var WEBGL = {
  isWebGLAvailable: function () {
    try {
      var canvas = document.createElement("canvas");
      return !!(
        window.WebGLRenderingContext &&
        (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
      );
    } catch (e) {
      return false;
    }
  },

  isWebGL2Available: function () {
    try {
      var canvas = document.createElement("canvas");
      return !!(window.WebGL2RenderingContext && canvas.getContext("webgl2"));
    } catch (e) {
      return false;
    }
  },

  getWebGLErrorMessage: function () {
    return this.getErrorMessage(1);
  },

  getWebGL2ErrorMessage: function () {
    return this.getErrorMessage(2);
  },

  getErrorMessage: function (version) {
    var names = {
      1: "WebGL",
      2: "WebGL 2",
    };

    var contexts = {
      1: window.WebGLRenderingContext,
      2: window.WebGL2RenderingContext,
    };

    var message =
      'Tu $0 no soporta <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">$1</a>, asi que no se puede mostrar todo el contenido de la página.';

    var element = document.createElement("div");
    element.id = "webglmessage";
    element.style.fontFamily = "monospace";
    element.style.fontSize = "13px";
    element.style.fontWeight = "normal";
    element.style.textAlign = "center";
    element.style.background = "#fff";
    element.style.color = "#000";
    element.style.padding = "1.5em";
    element.style.width = "400px";
    element.style.margin = "5em auto 0";

    if (contexts[version]) {
      message = message.replace("$0", "tarjeta gráfica");
    } else {
      message = message.replace("$0", "navegador");
    }

    message = message.replace("$1", names[version]);

    element.innerHTML = message;

    return element;
  },
};

export { WEBGL };
