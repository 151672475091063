<template>
  <div>
    <label class="radio-button">
      <input type="radio" :name="group" value="value" />
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    group: String,
  },
};
</script>

<style>
.radio-button > input[type="radio"] {
  display: none;
}

.radio-button > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: silver;
}

.radio-button > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    #1468e6 0%,
    #1468e6 40%,
    transparent 50%,
    transparent
  );
}

.radio-button > input[type="radio"]:checked + * {
  color: #1468e6;
}
</style>
