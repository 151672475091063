<template>
  <div class="ID1" id="ID1">
    <SwapArrows v-on:upClick="goToID5" v-on:downClick="goToID2"></SwapArrows>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToID"></Button>
    <div class="AI1-block">
      <div class="AI2-block-content">
        <div class="AI2-subtitle1">REALIDAD</div>
        <div class="AI2-subtitle2">AUMENTADA</div>
        <div class="AI1-space"></div>
        <div class="AI2-description">
          Conjunto de técnicas que permiten superponer elementos virtuales en la
          realidad. Con gran potencial para capacitaciones y uso publicitario
          masivo (ATL) por su alto grado de interactividad y fácil uso en
          dispositivos móviles.
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import Image1 from "../atoms/Image1";
import SidebarID from "../organisms/SidebarID";
import Swipe from "../../vendor/Swipe";
import SwapArrows from "../molecules/SwapArrows";

export default {
  name: "ID1",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { Button, InfoViewer, Image1, SidebarID, SwapArrows },
  props: {},
  methods: {
    goToID() {
      this.$router.push({ name: "id" });
      this.$store.commit("goToShape", { figureName: "cube" });
    },
    goToID2() {
      this.$router.push({ name: "id2" });
    },
    goToID5() {
      this.$router.push({ name: "id5" });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 700) {
        this.$store.commit("changeSlideBarIDState", { value: false });
      } else {
        this.$store.commit("changeSlideBarIDState", { value: true });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: -0.35,
      y: 1.8,
      zoom: 40,
      centerx: 20,
      centery: 12,
      centerz: 0,
      sx: -0.35,
      sy: 1.8,
      szoom: 40,
      scenterx: 20,
      scentery: 12,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 290, s: 1, l: 0.36 });
    this.$store.commit("changeBackgroud360Image", { index: 2 });
    this.$store.commit("activateBrain", { active: false });
    this.$store.commit("activateRobot", { active: true });
    this.$store.commit("changeBackgroudVideo", { src: "ar.mp4" });
    this.$store.commit("updateVideoVisibility", { active: true });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    if (this.innerWidth < 700) {
      this.$store.commit("changeSlideBarIDState", { value: false });
    } else {
      this.$store.commit("changeSlideBarIDState", { value: true });
    }
    this.$store.commit("changeSlideBarIDColor", { color: "#C437FF" });
    Swipe.detectSwipe("ID1", (el, dir) => {
      if (dir == "up") {
        this.$router.push({ name: "id5" });
      } else if (dir == "down") {
        this.$router.push({ name: "id2" });
      }
    });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false;
      }
    },
  },
};
</script>
<style>
.ID1 {
  color: white;
  width: 100%;
  height: 100%;
}

.ID1-title {
  width: 100%;
  text-align: center;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 300;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .ID1-title {
    font-size: 17px;
  }
}

.ID1-subtitle1 {
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .ID1-subtitle1 {
    font-size: 17px;
  }
}

.ID1-subtitle2 {
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .ID1-subtitle2 {
    font-size: 17px;
  }
}

.ID1-description {
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 1.6;
}

@media screen and (max-width: 700px) {
  .ID1-description {
    font-size: 11px;
  }
}
</style>
