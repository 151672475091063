<template>
  <div>
    <div class="tab-size character-style-1">
      {{ text }}
      <div class="base-animation"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabButton",
  props: {
    text: String,
  },
};
</script>

<style>
.tab-size {
  width: fit-content;
  transition: 0.3s;
}

.base-animation {
  margin-top: 5px;
  border-bottom: 2px solid var(--color12);
  width: 0%;
  transition: width 0.4s;
}

.character-style-1:hover .base-animation {
  width: 100%;
}

.character-style-1:hover {
  color: var(--color6);
}
</style>
