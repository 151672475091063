<template>
  <div class="base">
    <Back></Back>
    <BrainScene ref="brainScene"></BrainScene>
    <Front></Front>
    <Loading></Loading>
  </div>
</template>

<script>
import BrainScene from "../organisms/BrainScene";
import Back from "./Back";
import Front from "./Front"; 
import Loading from "./Loading";

export default {
  name: "Base",
  components: { BrainScene, Back, Front, Loading },
  props: {},
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth >= 700) {
        this.$store.commit("changeMenuState", { value: false });
      }
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style></style>
