<template>
  <div class="info-viewer-container" :style="{
    'top': 'calc(' + (innerWidth < 700 ? stop : top) + 'vh + 80px)', 
    'left': (innerWidth < 700 ? sleft : left) + 'vw', 
    'width': (innerWidth < 700 ? swidth : width) + 'vw',
    'height': 'calc(' + (innerWidth < 700 ? sheight : height) + 'vh - 80px)'
    }">
    <div class="info-viewer-content">
        <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "InfoViewer",
  data() {
    return {
      innerWidth: window.innerWidth
    }
  },
  props: {
      top: Number,
      left: Number,
      width: Number,
      height: Number,
      stop: Number,
      sleft: Number,
      swidth: Number,
      sheight: Number,
  },
  components: {},
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
.info-viewer-container {
    position: absolute;
    animation: 2s ease-out 0s 1 infoVieverAppear;
}
@keyframes infoVieverAppear {
  0% {
    transform: scaleY(0.9);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

.info-viewer-content{
    width: 100%;
    height: 100%;
    /*background: blue;*/
}

</style>