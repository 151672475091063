<template>
  <div>
    <div
      id="brain-scene-container"
      :class="state != 'idle' ? 'brain-scene-no-events' : ''"
    ></div>
    <!--<video id="video2" class="front-video-test" width="320" height="240" autoplay loop muted playsinline >
      <source :src="publicPath + 'test_videos/video.mp4'" type="video/mp4">
      Your browser does not support the video tag.
    </video>-->
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "../../vendor/treejs/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader.js";
import { WEBGL } from "../../vendor/treejs/WebGL.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { LuminosityShader } from "three/examples/jsm/shaders/LuminosityShader.js";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass.js";

export default {
  name: "BrainScene",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      positions: {
        x: 0,
        y: 0,
      },
      canvas: null,
      camera: null,
      controls: null,

      // random: Particulas aleatorias en el espacio sin textura del cerebro
      // particles_transition: Particulas formando el cerebro sin textura del cerebro
      // brain_texture_apear: Particulas desapareciendo y textura del cerebro apareciendo
      // idle: Textura del cerebro sin particulas con interacción del usuario (Estado en el que se muestra la información de la pagina)
      // brain_texture_disapear: Particulas apareciendo y textura del cerebro desapareciendo
      state: "idle",
      movePositions: {
        x: 0,
        y: 0,
        zoom: 60,
        centerx: 20,
        centery: 5,
        centerz: 0,
      },
      video: null,
      videoSrc: "particula-sony-vegas.mp4",
      videoid: "video",
      scene: null,
      videoMesh: null,
      playPromise: null,
      raycaster: null,
      mouse: null,
      renderer: null,
      particles: [],
      geometry: null,
      brainRoot: null,
      robotRoot: null,
      rootLight: null,
      generalColor: [0.5, 0.5, 0.5],
      generalPointerColor: [0.5, 0.5, 0.5],
      canChangeColor: true,
      mixer: null,
      clock: null,
      boomer: [],
      particleCount: null,
      particlesData: [],
      particlePositions: null,
      rHalf: null,
      effectController: null,
      positions2: null,
      colors: null,
      linesMesh: null,
      pointCloud: null,
      vertices: [],
      group: null,
      brain_texture_opacity: 1,
      back360Mesh: null,
      back360Index: -1,
      back360SrcList: ["back16.png", "back22.png", "back25.png"],
      back360SrcTextures: [null, null, null],
      back360SrcMaterials: [null, null, null],
      back360Meshes: [null, null, null],
      goToPositionValues: null,
      activeBrain: true,
      activeRobot: false,
      shapes: new Map(),
      video1: null,
      //trajectory: [],
      timeOut1: null,
      timeOut2: null,
      videoMaterial: null,
      videoActive: false,
      pointLight: null,
      composer: null,
    };
  },
  methods: {
    configScene() {
      // Crear escena
      this.canvas = document.querySelector("#brain-scene-container");

      // Crear renderizador de la escena
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        gammaFactor: 2.2,
        gammaOutput: true,
      });
      this.renderer.setClearColor(0x000000, 0);

      // Configurar camara
      const fov = 45;
      const aspect = 1; // the canvas default
      const near = 0.1;
      const far = 2000;
      this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      this.camera.position.set(0, 10, 50);

      // Crear Escena
      this.scene = new THREE.Scene();
    },
    configCameraConstrols() {
      // Configuirar controles de la camara
      this.controls = new OrbitControls(this.camera, this.canvas);
      this.controls.target.set(
        this.movePositions.centerx,
        this.movePositions.centery,
        this.movePositions.centerz
      );
      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.01;
      this.controls.minPolarAngle = -Infinity; // radians
      this.controls.maxPolarAngle = Infinity; // radians
      this.controls.minAzimuthAngle = -Infinity; // radians
      this.controls.maxAzimuthAngle = Infinity; // radians
      this.controls.rotateSpeed = 0.2;
      this.controls.enableZoom = false;
      this.controls.zoomSpeed = 0.5;
      this.controls.enablePan = false;
      this.controls.minDistance = 0.1;
      this.controls.maxDistance = 600;
      this.controls.update();
    },
    configRaycaster() {
      // Crear detector de objetos con el mouse
      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
    },
    configHemisphereLights() {
      // Añadir luz general
      const skyColor = 0xffffff;
      const groundColor = 0xffffff;
      const intensity = 1;
      const light = new THREE.HemisphereLight(skyColor, groundColor, intensity);
      this.scene.add(light);
    },
    configPointLights() {
      // Añadir luz en un punto en especifico
      const color = 0xffffff;
      const intensity = 0.5;
      const light = new THREE.PointLight(color, intensity);
      light.position.set(0, 300, 0);
      this.scene.add(light);
      this.pointLight = light;
    },
    configObjMtlModel() {
      // Añadir modelo obj y material mtl
      const self = this;
      var mtlLoader = new MTLLoader();
      mtlLoader.setPath(this.publicPath + "models/");
      mtlLoader.load("brain.mtl", function (materials) {
        self.$store.commit("setBrainLoading", { value: 20 });
        materials.preload();
        var objLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.setPath(self.publicPath + "models/");
        objLoader.load("BrainLight.obj", (root) => {
          self.$store.commit("setBrainLoading", { value: 40 });
          self.scene.add(root);
          //root.position.set(20, -10, 0);
          root.position.set(20, -10, 0);
          root.rotation.set(0, 0, 0);
          root.scale.set(17, 17, 17);
          self.brainRoot = root;

          //self.clock = new THREE.Clock();
          //var explosionTexture = new THREE.ImageUtils.loadTexture( 'test_models/explosion.jpg' );
          //self.boomer = new self.textureAnimator( explosionTexture, 4, 4, 16, 55 ); // texture, #horiz, #vert, #total, duration.
          //var explosionMaterial = new THREE.MeshBasicMaterial( { map: explosionTexture } );

          self.brainRoot.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              /*child.material.emissive.setHSL(
                  self.generalColor[0],
                  self.generalColor[1],
                  self.generalColor[2]
                );*/
              child.material.color.setHSL(
                self.generalColor[0],
                self.generalColor[1],
                self.generalColor[2]
              );
              child.material.side = THREE.DoubleSide;
              child.material.blendin = THREE.AdditiveBlending;
              child.material.depthTest = false;
              child.material.transparent = true;
              child.material.depthWrite = false;
              child.renderOrder = 2;
              child.material.opacity = self.brain_texture_opacity;
              //child.material.map.repeat.set(5, 5);
              //child.material.map = THREE.ImageUtils.loadTexture( self.publicPath + "models/Brain_gray.png" );
              //child.material.needsUpdate = true;

              //child.material = explosionMaterial
              child.material.needsUpdate = true;
            }
          });
          self.$store.commit("setBrainLoading", { value: 70 });
          self.configParticles();
          self.$store.commit("setBrainLoading", { value: 100 });
        });
      });
    },
    configFbxModel() {
      // Añadir modelo FBX
      const loader = new FBXLoader();
      const self = this;
      loader.setPath(this.publicPath + "models/");
      loader.load("robot.fbx", function (object) {
        //console.log("jjajajaja");
        //console.log(object);
        self.$store.commit("setRobotLoading", { value: 50 });
        /*object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material.map = THREE.ImageUtils.loadTexture( self.publicPath + "models/Robot.png" );
              child.material.needsUpdate = true;
            }
        });*/

        object.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            child.material.transparent = true;
            //child.material.side = THREE.DoubleSide;
            child.material.opacity = 0;
            child.renderOrder = 4;
          }
        });

        //object.children[3].visible = false;

        self.clock = new THREE.Clock();
        self.mixer = new THREE.AnimationMixer(object);
        const action = self.mixer.clipAction(object.animations[0]);
        action.play();
        /*object.traverse(function (child) {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });*/

        self.scene.add(object);
        self.robotRoot = object;
        object.position.set(20, 0, 0);
        object.rotation.set(0, 0, 0);
        object.scale.set(0.11, 0.11, 0.11);
        self.$store.commit("setRobotLoading", { value: 100 });
      });
    },
    configGlbModel() {
      // Añadir modelo gltf (glb)
      const loader = new GLTFLoader();
      var dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("js/libs/draco/gltf/");
      loader.setDRACOLoader(dracoLoader);
      loader.setPath(this.publicPath + "models/");
      const self = this;
      loader.load(
        "robot_silla.glb",
        function (object) {
          //console.log(object);
          self.$store.commit("setRobotLoading", { value: 50 });

          object.scene.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material.transparent = true;
              //child.material.side = THREE.DoubleSide;
              child.material.opacity = 0;
              child.renderOrder = 4;
            }
          });

          self.clock = new THREE.Clock();
          self.mixer = new THREE.AnimationMixer(object.scene);
          object.animations.forEach((clip) => {
            self.mixer.clipAction(clip).play();
          });
          self.scene.add(object.scene);
          object.scene.position.set(20, 0, 20);
          object.scene.rotation.set(0, Math.PI, 0);
          object.scene.scale.set(4, 4, 4);
          self.robotRoot = object.scene;
          self.robotRoot.traverse(function (child) {
            if (child.name.startsWith("fire_part")) {
              /*var explosionTexture = new THREE.ImageUtils.loadTexture( 'test_models/sprite-animation-layers.jpg' );
              explosionTexture.repeat.set(0.01, 0.01);
              explosionTexture.offset.set(0.01, 0.01);
              self.boomer.push(new TextureAnimator( explosionTexture, 4, 4, 16, 55 )); // texture, #horiz, #vert, #total, duration.
              var explosionMaterial = new THREE.MeshBasicMaterial( { map: explosionTexture } );
              child.material = explosionMaterial;*/
              //child.material.emissive.setHSL( self.generalColor[0], self.generalColor[1], self.generalColor[2] );
            }
          });

          self.$store.commit("setRobotLoading", { value: 100 });
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );
    },
    configColladaModel() {
      var elf;
      var self = this;
      const loadingManager = new THREE.LoadingManager(function () {
        self.scene.add(elf);
      });
      const loader = new ColladaLoader(loadingManager);
      loader.load("test_models/particulas.dae", function (collada) {
        elf = collada.scene;
      });
    },
    textureAnimator(
      texture,
      tilesHoriz,
      tilesVert,
      numTiles,
      tileDispDuration
    ) {
      // note: texture passed by reference, will be updated by the update function.
      this.tilesHorizontal = tilesHoriz;
      this.tilesVertical = tilesVert;
      // how many images does this spritesheet contain?
      //  usually equals tilesHoriz * tilesVert, but not necessarily,
      //  if there at blank tiles at the bottom of the spritesheet.
      this.numberOfTiles = numTiles;
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical);
      // how long should each image be displayed?
      this.tileDisplayDuration = tileDispDuration;
      // how long has the current image been displayed?
      this.currentDisplayTime = 0;
      // which image is currently being displayed?
      this.currentTile = 0;
      this.update = function (milliSec) {
        this.currentDisplayTime += milliSec;
        while (this.currentDisplayTime > this.tileDisplayDuration) {
          this.currentDisplayTime -= this.tileDisplayDuration;
          this.currentTile++;
          if (this.currentTile == this.numberOfTiles) this.currentTile = 0;
          var currentColumn = this.currentTile % this.tilesHorizontal;
          texture.offset.x = currentColumn / this.tilesHorizontal;
          var currentRow = Math.floor(this.currentTile / this.tilesHorizontal);
          texture.offset.y = currentRow / this.tilesVertical;
        }
      };
    },
    configCubeSprite() {
      this.clock = new THREE.Clock();
      var explosionTexture = new THREE.ImageUtils.loadTexture(
        "test_models/explosion.jpg"
      );
      this.boomer = new this.textureAnimator(explosionTexture, 4, 4, 16, 55); // texture, #horiz, #vert, #total, duration.
      var explosionMaterial = new THREE.MeshBasicMaterial({
        map: explosionTexture,
      });
      var cubeGeometry = new THREE.BoxGeometry(50, 50, 50);
      var cube = new THREE.Mesh(cubeGeometry, explosionMaterial);
      cube.position.set(0, 26, 0);
      this.scene.add(cube);
    },
    updateSprite() {
      // Animar modelo
      if (this.clock) {
        var delta = this.clock.getDelta();
        this.boomer.update(1000 * delta);
      }
    },
    updateAnimations() {
      // Animar modelo
      if (this.clock) {
        const delta = this.clock.getDelta();
        if (this.mixer) this.mixer.update(delta);
      }
    },
    configMoves() {
      // Controlar seguimiento del mouse
      const self = this;
      function onMouseSeg() {
        if (self.state == "idle") {
          const customEvent = {
            clientX: self.positions.x,
            clientY: self.positions.y,
          };
          self.controls.handleMouseMoveRotate(customEvent);
        } else if (self.state == "particles_transition") {
          const finished = self.controls.handlePositionMouseMoveRotate(
            self.movePositions
          );
          if (finished) {
            self.state = "brain_texture_appear";
            self.goToShape("brain");
          }
        }
      }
      function onDocumentMouseMove(event) {
        self.positions.x = event.clientX;
        self.positions.y = event.clientY;
      }
      setInterval(onMouseSeg, 20);
      document.addEventListener("mousemove", onDocumentMouseMove, false);
    },
    updateScene() {
      // Adaptar a la pantalla
      this.camera.aspect =
        this.renderer.domElement.clientWidth /
        this.renderer.domElement.clientHeight;
      this.camera.updateProjectionMatrix();

      // Asignar tamaño del canvas
      this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

      // Asignar ratio de pixeles
      this.renderer.setPixelRatio(window.devicePixelRatio);

      // Añadir canvas a la página
      this.canvas.append(this.renderer.domElement);
    },
    updateColorsAndOpacity() {
      // calculate objects intersecting the picking ray
      if (this.pointCloud) {
        /*const intersects = this.raycaster.intersectObjects([this.pointCloud], true);
        if (this.state == "idle") {
          intersects.forEach((intersect) => {
            // Detectar intersecciones
            this.changeRandomColor();
          });
          
        }*/

        var changeColor = false;
        if (this.generalPointerColor[0] > this.generalColor[0] + 0.02) {
          this.generalPointerColor[0] -= 0.01;
          changeColor = true;
        } else if (this.generalPointerColor[0] < this.generalColor[0] - 0.02) {
          this.generalPointerColor[0] += 0.01;
          changeColor = true;
        }

        if (this.generalPointerColor[1] > this.generalColor[1] + 0.02) {
          this.generalPointerColor[1] -= 0.01;
          changeColor = true;
        } else if (this.generalPointerColor[1] < this.generalColor[1] - 0.02) {
          this.generalPointerColor[1] += 0.01;
          changeColor = true;
        }

        if (this.generalPointerColor[2] > this.generalColor[2] + 0.02) {
          this.generalPointerColor[2] -= 0.01;
          changeColor = true;
        } else if (this.generalPointerColor[2] < this.generalColor[2] - 0.02) {
          this.generalPointerColor[2] += 0.01;
          changeColor = true;
        }

        if (changeColor) {
          this.pointCloud.material.color.setHSL(
            this.generalPointerColor[0],
            this.generalPointerColor[1],
            this.generalPointerColor[2]
          );
        }

        if (
          this.activeBrain &&
          (this.state == "brain_texture_disapear" ||
            this.state == "particles_transition")
        ) {
          if (this.pointCloud.material.opacity > 1) {
            this.pointCloud.material.opacity = 1;
          } else if (this.pointCloud.material.opacity < 1) {
            this.pointCloud.material.opacity += 0.02;
          }
        } else {
          if (this.pointCloud.material.opacity < 0) {
            this.pointCloud.material.opacity = 0;
          } else if (this.pointCloud.material.opacity > 0) {
            this.pointCloud.material.opacity -= 0.005;
          }
        }

        /*for (let i = 0; i < this.pointCloud.length; i++) {
          this.pointCloud[i].material.color.setHSL(
            this.generalPointerColor[0],
            this.generalPointerColor[1],
            this.generalPointerColor[2]
          );
          this.pointCloud[i].material.needsUpdate = true;
        }*/

        if (this.back360Mesh && changeColor) {
          this.back360Mesh.material.color.setHSL(
            this.generalPointerColor[0],
            this.generalPointerColor[1],
            this.generalPointerColor[2]
          );
        }

        /*if (this.videoMesh && changeColor) {
          this.videoMesh.material.color.setHSL(
            this.generalPointerColor[0],
            this.generalPointerColor[1],
            this.generalPointerColor[2]
          );
        }*/

        var change360Opacity;
        for (var i = 0; i < this.back360SrcMaterials.length; i++) {
          //console.log(this.back360Meshes[i]);
          if (this.back360Meshes[i]) {
            change360Opacity = false;
            if (this.back360Mesh == this.back360Meshes[i]) {
              if (this.back360Meshes[i].material.opacity < 0.5) {
                this.back360Meshes[i].material.opacity += 0.01;
                change360Opacity = true;
              } else if (this.back360Meshes[i].material.opacity > 0.5) {
                this.back360Meshes[i].material.opacity = 0.5;
                change360Opacity = true;
              }
            } else {
              if (this.back360Meshes[i].material.opacity > 0) {
                this.back360Meshes[i].material.opacity -= 0.01;
                change360Opacity = true;
              } else if (this.back360Meshes[i].material.opacity < 0) {
                this.back360Meshes[i].material.opacity = 0;
                change360Opacity = true;
              }
            }
            if (change360Opacity) {
              this.back360Meshes[i].material.needsUpdate = true;
            }
          }
        }

        if (this.brainRoot) {
          const self = this;
          this.brainRoot.traverse(function (child) {
            //if (child.name.startsWith('fire_part')) {
            //  child.material.emissive.setHSL( this.generalPointerColor[0], this.generalPointerColor[1], this.generalPointerColor[2] * 0.4);
            //}
            if (child instanceof THREE.Mesh) {
              /*child.material.emissive.setHSL(
                self.generalPointerColor[0],
                self.generalPointerColor[1],
                self.generalPointerColor[2]
              );*/
              if (changeColor) {
                child.material.color.setHSL(
                  self.generalPointerColor[0],
                  self.generalPointerColor[1],
                  self.generalPointerColor[2]
                );
              }

              // Cambiar opacidad
              if (self.activeBrain) {
                if (self.state == "brain_texture_appear") {
                  if (child.material.opacity > 1) {
                    child.material.opacity = 1;
                    self.state = "idle";
                  } else if (child.material.opacity < 1) {
                    child.material.opacity += 0.003;
                  }
                }
                if (
                  self.state == "brain_texture_disapear" ||
                  self.state == "particles_transition"
                ) {
                  if (child.material.opacity < 0) {
                    child.material.opacity = 0;
                  } else if (child.material.opacity > 0) {
                    child.material.opacity -= 0.01;
                  }
                }
              } else {
                if (self.state == "brain_texture_appear") {
                  self.state = "idle";
                }
                if (
                  self.state == "brain_texture_disapear" ||
                  self.state == "particles_transition"
                ) {
                  if (child.material.opacity < 0) {
                    child.material.opacity = 0;
                  } else if (child.material.opacity > 0) {
                    child.material.opacity -= 0.01;
                  }
                }
              }
            }
          });
        }

        if (this.robotRoot) {
          const self = this;
          this.robotRoot.traverse(function (child) {
            //if (child.name.startsWith('fire_part')) {
            //  child.material.emissive.setHSL( this.generalPointerColor[0], this.generalPointerColor[1], this.generalPointerColor[2] * 0.4);
            //}
            if (child instanceof THREE.Mesh) {
              /*child.material.emissive.setHSL(
                self.generalPointerColor[0],
                self.generalPointerColor[1],
                self.generalPointerColor[2]
              );*/
              /*if (changeColor) {
                child.material.color.setHSL(
                  self.generalPointerColor[0],
                  self.generalPointerColor[1],
                  self.generalPointerColor[2]
                );
              }*/

              // Cambiar opacidad
              if (self.activeRobot) {
                if (child.material.opacity > 1) {
                  child.material.opacity = 1;
                  self.controls.minPolarAngle = self.controls.getPolarAngle();
                  self.controls.maxPolarAngle = self.controls.getPolarAngle();
                  if (self.controls.getAzimuthalAngle() < 0) {
                    self.controls.minAzimuthAngle = self.controls.getAzimuthalAngle();
                    self.controls.maxAzimuthAngle = self.controls.getAzimuthalAngle() /* + Math.PI / 12*/;
                  } else {
                    self.controls.minAzimuthAngle = self.controls.getAzimuthalAngle() /* - Math.PI / 12*/;
                    self.controls.maxAzimuthAngle = self.controls.getAzimuthalAngle();
                  }
                  self.controls.rotateSpeed = 10;
                  self.controls.dampingFactor = 1;
                  self.controls.enabled = false;
                } else if (child.material.opacity < 1) {
                  child.material.opacity += 0.005;
                }
              } else {
                if (child.material.opacity < 0) {
                  child.material.opacity = 0;
                  self.controls.minPolarAngle = -Infinity;
                  self.controls.maxPolarAngle = Infinity;
                  self.controls.minAzimuthAngle = -Infinity;
                  self.controls.maxAzimuthAngle = Infinity;
                  self.controls.rotateSpeed = 0.2;
                  self.controls.dampingFactor = 0.01;
                  self.controls.enabled = true;
                } else if (child.material.opacity > 0) {
                  child.material.opacity -= 0.01;
                }
              }
            }
          });
          /*if (self.activeRobot) {
            this.robotRoot.children[3].visible = true;
          } else {
            this.robotRoot.children[3].visible = false;
          }*/
        }

        if (this.activeRobot) {
          if (this.pointLight.intensity > 0.5) {
            this.pointLight.intensity = 0.5;
          } else if (this.pointLight.intensity < 0.5) {
            this.pointLight.intensity += 0.02;
          }
        } else {
          if (this.pointLight.intensity < 0) {
            this.pointLight.intensity = 0;
          } else if (this.pointLight.intensity > 0) {
            this.pointLight.intensity -= 0.005;
          }
        }
      }
    },
    configRender() {
      // Función para renderizar el escenario
      const self = this;
      function render() {
        // Actualizar escena
        self.updateScene();

        // Actualizar controles para incercia
        self.controls.update();

        // Actualizar el rayo de selección entre el mouse y la cámara
        //self.raycaster.setFromCamera(self.mouse, self.camera);

        // Actualizar particulas
        //self.line();
        self.updateParticles();
        //self.parametric();

        // Actualizar colores
        self.updateColorsAndOpacity();

        // Animar modelo
        //self.updateSprite();
        self.updateAnimations();

        //Actualizar visibilidad de video
        self.updateVideoVisibility();

        // Actualizar postprocesado
        //self.updatePostProcessing();

        // Renderizar
        self.renderer.render(self.scene, self.camera);

        // Informar a la pagina que se realiará una animación
        requestAnimationFrame(render);
      }

      // Informar a la pagina que se realiará una animación
      requestAnimationFrame(render);
    },
    goToPosition(
      x,
      y,
      zoom,
      centerx,
      centery,
      centerz,
      sx,
      sy,
      szoom,
      scenterx,
      scentery,
      scenterz
    ) {
      clearTimeout(this.timeOut1);
      clearTimeout(this.timeOut2);

      if (window.innerWidth < 700) {
        x = sx;
        y = sy;
        zoom = szoom;
        centerx = scenterx;
        centery = scentery;
        centerz = scenterz;
      }

      centerx = centerx + (20 - centerx) * (1 - window.innerWidth / 1536) * 0.5;
      zoom = zoom * ((1536 / window.innerWidth) * 0.2 + 0.8);

      this.brain_texture_opacity = 0;
      this.state = "brain_texture_disapear";

      const self = this;
      clearTimeout(self.timeOut2);
      self.timeOut2 = setTimeout(function () {
        if (self.state == "brain_texture_disapear") {
          self.state = "particles_transition";
          self.goToShape("cube");
          self.movePositions = {
            x: x,
            y: y,
            zoom: zoom,
            centerx: centerx,
            centery: centery,
            centerz: centerz,
          };
        }
      }, 500);
    },
    changeBackgroudVideo(src) {
      var lastOpacity = 0;
      if (this.video) {
        lastOpacity = this.videoMesh.material.opacity;
        this.video.remove();
      }

      var html =
        '<video id="video" width="320" height="240" autoplay loop muted playsinline ><source src="' +
        this.publicPath +
        "videos/" +
        src +
        '" type="video/mp4"> Your browser does not support the video tag.</video>';
        
        /*<video id="video2" class="front-video-test" width="320" height="240" autoplay loop muted playsinline >
          <source :src="publicPath + 'test_videos/video.mp4'" type="video/mp4">
          Your browser does not support the video tag.
        </video>*/
      /*var html =
        '<video id="video" loop crossorigin="anonymous" style="display:none" muted="true" src="https://storage.googleapis.com/nullspace-col.appspot.com/video.mp4"></video>';*/
      var temp = document.createElement("div");
      temp.innerHTML = html;
      var video = temp.firstChild;
      const self = this;
      video.addEventListener(
        "loadeddata",
        function () {
          self.playPromise = video.play();
          video.addEventListener("play", function () {
            this.currentTime = 3;
          });

          const videoTexture = new THREE.VideoTexture(video);
          const geometry = new THREE.PlaneGeometry(30, 20, 10);
          self.videoMaterial = new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: videoTexture,
            transparent: true,
          });
          //self.videoMaterial.side = THREE.BackSide;
          const mesh = new THREE.Mesh(geometry, self.videoMaterial);
          mesh.name = "brain_model";
          mesh.position.x = 20;
          mesh.position.y = 10;
          mesh.position.z = 0;
          mesh.renderOrder = 3;
          //mesh.rotation.y = Math.PI;
          mesh.scale.x = mesh.scale.y = mesh.scale.z = 1;
          self.scene.remove(self.scene.getObjectByName("brain_model"));
          self.scene.add(mesh);
          self.video = video;
          self.videoMesh = mesh;
          /*self.videoMaterial.color.setHSL(
            self.generalColor[0],
            self.generalColor[1],
            self.generalColor[2]
          );*/
          self.videoMesh.material.side = THREE.DoubleSide;
          self.videoMesh.material.opacity = lastOpacity;
        },
        false
      );
    },
    updateVideoVisibility() {
      if (this.videoMesh) {
        // console.log(this.videoActive);
        if (this.videoActive) {
          if (this.videoMesh.material.opacity < 1) {
            this.videoMesh.material.opacity += 0.01;
          } else {
            this.videoMesh.material.opacity = 1;
          }
        } else {
          if (this.videoMesh.material.opacity > 0) {
            this.videoMesh.material.opacity -= 0.01;
          } else {
            this.videoMesh.material.opacity = 0;
          }
        }
      }
    },
    configBackgroud360Images() {
      const self = this;
      var cont = 0;
      for (var i = 0; i < this.back360SrcList.length; i++) {
        self.back360SrcTextures[i] = new THREE.TextureLoader().load(
          self.publicPath + "images/" + self.back360SrcList[i],
          (texture) => {
            self.$store.commit("setBack360Loading", {
              value: ((cont + 1) * 100) / self.back360SrcList.length,
            });
            cont++;
          }
        );
        self.back360SrcMaterials[i] = new THREE.MeshBasicMaterial({
          map: self.back360SrcTextures[i],
          transparent: true,
          depthWrite: false,
        });
        self.back360SrcMaterials[i].opacity = 0;
        var geometry = new THREE.SphereGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1);
        self.back360Meshes[i] = new THREE.Mesh(
          geometry,
          self.back360SrcMaterials[i]
        );
        self.back360Meshes[i].rotation.set(0, -Math.PI / 2, 0);
        self.scene.add(self.back360Meshes[i]);
      }
    },
    changeBackgroud360Image(index) {
      if (this.back360Index == index) {
        return;
      }
      this.back360Index = index;
      this.back360Mesh = this.back360Meshes[index];
    },

    animate() {
      let vertexpos = 0;
      let colorpos = 0;
      let numConnected = 0;
      /*
      let particleCount = this.particleCount;
      let particlesData = this.particlesData;
      let particlePositions = this.particlePositions;
      let rHalf = this.rHalf;
      let effectController = this.effectController;
      let positions = this.positions2;
      let colors = this.colors;
      let linesMesh = this.linesMesh;
      let pointCloud = this.pointCloud;*/

      /*for (let i = 0; i < this.particleCount; i++)
        this.particlesData[i].numConnections = 0;*/
      /*
      for (let i = 0; i < this.particleCount; i++) {
        // get the particle
        const particleData = this.particlesData[i];
        this.this.particlePositions[i * 3] += particleData.velocity.x;
        this.particlePositions[i * 3 + 1] += particleData.velocity.y;
        this.particlePositions[i * 3 + 2] += particleData.velocity.z;

        // Define los limites de recorrido dentro del cubo

        if (
          this.particlePositions[i * 3 + 1] < -this.rHalf ||
          this.particlePositions[i * 3 + 1] > this.rHalf
        )
          particleData.velocity.y = -particleData.velocity.y;

        if (
          this.particlePositions[i * 3] < -this.rHalf ||
          this.particlePositions[i * 3] > this.rHalf
        )
          particleData.velocity.x = -particleData.velocity.x;

        if (
          this.particlePositions[i * 3 + 2] < -this.rHalf ||
          this.particlePositions[i * 3 + 2] > this.rHalf
        )
          particleData.velocity.z = -particleData.velocity.z;

        if (
          this.effectController.limitConnections &&
          particleData.numConnections >= this.effectController.maxConnections
        )
          continue;

        // Check collision
        for (let j = i + 1; j < this.particleCount; j++) {
          const particleDataB = this.particlesData[j];
          if (
            this.effectController.limitConnections &&
            particleDataB.numConnections >= this.effectController.maxConnections
          )
            continue;

          const dx =
            this.particlePositions[i * 3] - this.particlePositions[j * 3];
          const dy =
            this.particlePositions[i * 3 + 1] -
            this.this.particlePositions[j * 3 + 1];
          const dz =
            this.particlePositions[i * 3 + 2] -
            this.particlePositions[j * 3 + 2];
          const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

          if (dist < this.effectController.minDistance) {
            particleData.numConnections++;
            particleDataB.numConnections++;

            const alpha = 1.0 - dist / this.effectController.minDistance;

            this.positions[vertexpos++] = this.particlePositions[i * 3];
            this.positions[vertexpos++] = this.particlePositions[i * 3 + 1];
            this.positions[vertexpos++] = this.particlePositions[i * 3 + 2];

            this.positions[vertexpos++] = this.particlePositions[j * 3];
            this.positions[vertexpos++] = this.particlePositions[j * 3 + 1];
            this.positions[vertexpos++] = this.particlePositions[j * 3 + 2];

            this.colors[colorpos++] = alpha;
            this.colors[colorpos++] = alpha;
            this.colors[colorpos++] = alpha;

            this.colors[colorpos++] = alpha;
            this.colors[colorpos++] = alpha;
            this.colors[colorpos++] = alpha;

            numConnected++;
          }
        } 
      }

     this.linesMesh.geometry.setDrawRange(0, numConnected * 2);
      this.linesMesh.geometry.attributes.position.needsUpdate = true;
      this.linesMesh.geometry.attributes.color.needsUpdate = true;

      this.pointCloud.geometry.attributes.position.needsUpdate = true; */

      //requestAnimationFrame(animate);
      //render();
    },
    configParticles() {
      // Crear material circular
      /*function createCanvasMaterial(color, size) {
        var matCanvas = document.createElement("canvas");
        matCanvas.width = matCanvas.height = size;
        var matContext = matCanvas.getContext("2d");
        var texture = new THREE.Texture(matCanvas);
        var center = size / 2;
        matContext.beginPath();
        matContext.arc(center, center, size / 2, 0, 2 * Math.PI, false);
        matContext.closePath();
        matContext.fillStyle = color;
        matContext.fill();
        texture.needsUpdate = true;
        return texture;
      }*/

      // Incluir partículas
      const geometry = new THREE.BufferGeometry();
      this.geometry = geometry;
      const vertices = [];
      this.vertices = vertices;
      //const materials = [];

      /*for ( let i = 0; i < 2000; i ++ ) {
        const x = ((Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random()) - 3) / 3 * 1000;
        const y = ((Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random()) - 3) / 3 * 1000;
        const z = ((Math.random() + Math.random() + Math.random() + Math.random() + Math.random() + Math.random()) - 3) / 3 * 1000;
        vertices.push( x, y, z );          
      }*/

      //root.position.set(20, -10, 0);
      //root.rotation.set(0, 0, 0);
      //root.scale.set(25, 25, 25);

      for (let j = 0; j < this.brainRoot.children.length; j++) {
        for (
          let i = 0;
          i < this.brainRoot.children[j].geometry.attributes.position.count * 3;
          i += 3 * 3
        ) {
          const x =
            this.brainRoot.children[j].geometry.attributes.position.array[i] *
              17 +
            20;
          const y =
            this.brainRoot.children[j].geometry.attributes.position.array[
              i + 1
            ] *
              17 -
            10;
          const z =
            this.brainRoot.children[j].geometry.attributes.position.array[
              i + 2
            ] *
              17 +
            0;
          vertices.push(x, y, z);
        }
      }

      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );
      //geometry.setAttribute( 'position', this.brainRoot.children[0].geometry.attributes.position );

      //var parameters = [[[1, 1, 1], 0.6]];

      //var map = THREE.ImageUtils.loadTexture( self.publicPath + "models/pngegg.png" );
      /*
      for (let i = 0; i < parameters.length; i++) {
        const size = parameters[i][1];
        materials[i] = new THREE.PointsMaterial({
          opacity: 1,
          size: size,
          map: createCanvasMaterial("#ffffffff", 256),
          blending: THREE.AdditiveBlending,
          depthTest: false,
          transparent: true,
        });
        materials[i].color.setHSL(
          this.generalColor[0],
          this.generalColor[1],
          this.generalColor[2]
        );
        const particles = new THREE.Points(geometry, materials[i]);
        this.scene.add(particles);
        this.particles.push(particles);
      }*/
      this.drawParticlesAndLines2();

      // Asignar interacción
      /*const self = this;
      function onMouseMove(event) {
        event.preventDefault();
        const rect = self.renderer.domElement.getBoundingClientRect();
        self.mouse.x =
          ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        self.mouse.y =
          -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
      }

      window.addEventListener("mousemove", onMouseMove, false);*/
    },
    changeRandomColor() {
      this.changeBaseColor(Math.random(), Math.random(), Math.random());
    },
    changeBaseColor(h, s, l) {
      if (this.canChangeColor) {
        this.generalColor = [h / 360, s, l];
        /*this.canChangeColor = false;
        const self = this;
        setTimeout(function () {
          self.canChangeColor = true;
        }, 500);*/
      }
    },

    drawParticlesAndLines2() {
      this.particlePositions = new Float32Array(this.vertices);
      this.particlesFinalPlace = new Float32Array(this.vertices);
      let brain = new Float32Array(this.vertices);
      this.shapes.set("brain", brain);
      let particles = new THREE.BufferGeometry();
      let group = new THREE.Group();

      this.scene.add(group);

      const pointsMaterial = new THREE.PointsMaterial({
        size: 3,
        blending: THREE.AdditiveBlending,
        transparent: true,
        sizeAttenuation: false,
        depthTest: false,
      });

      pointsMaterial.color.setHSL(
        this.generalColor[0],
        this.generalColor[1],
        this.generalColor[2]
      );

      particles.setDrawRange(0, this.vertices.length);
      particles.setAttribute(
        "position",
        new THREE.BufferAttribute(this.particlePositions, 3).setUsage(
          THREE.DynamicDrawUsage
        )
      );

      // Crear sistema de particulas
      this.pointCloud = new THREE.Points(particles, pointsMaterial);
      group.add(this.pointCloud);

      // Llenar particlesData
      for (let i = 0; i <= this.vertices.length / 3; i++) {
        this.particlesData.push({
          velocity: new THREE.Vector3(0, 0, 0),
          selectedPoint: 0,
          inPlace: 0,
          t: 0,
          dt: 0,
          v: 0,
          change: true,
        });
      }
      this.createCube();
      this.setParticlesSpeed(0.3, 0.5);
      /*this.trajectory.push("brain");
      this.trajectory.push("cube");
      this.trajectory.push("brain");*/
    },
    setParticlesSpeed(vMin, vMax) {
      // Velocidad aleatoria para cada punto
      for (let i = 0; i <= this.vertices.length / 3; i++) {
        const v = Math.random() * (vMax - vMin) + vMin;
        this.particlesData[i].v = v;
      }
    },
    createCube() {
      let cube = new Float32Array(this.vertices);
      const m1 = -40;
      const m2 = 40;

      for (let i = 0; i < this.vertices.length / 3; i++) {
        const x = Math.random() * (m2 - m1) + m1 + 20;
        const y = Math.random() * (m2 - m1) + m1 - 10;
        const z = Math.random() * (m2 - m1) + m1;

        cube[i * 3] = x;
        cube[i * 3 + 1] = y;
        cube[i * 3 + 2] = z;
      }
      this.shapes.set("cube", cube);
    },
    createScreenCube() {
      let cube = new Float32Array(this.vertices);
      const m1 = 0;
      const m2 = 20;

      for (let i = 0; i < this.vertices.length / 3; i++) {
        const x = Math.random() * (m2 - m1) + 20;
        const y = (Math.random() * (m2 - m1)) / 2 - 10;
        const z = (Math.random() * (m2 - m1)) / 8 + 20;

        cube[i * 3] = x;
        cube[i * 3 + 1] = y;
        cube[i * 3 + 2] = z;
      }
      this.shapes.set("screen", cube);
    },
    getDirection(pos, figure) {
      let xInicial = this.particlePositions[pos * 3];
      let yInicial = this.particlePositions[pos * 3 + 1];
      let zInicial = this.particlePositions[pos * 3 + 2];

      let xDest = figure[pos * 3];
      let yDest = figure[pos * 3 + 1];
      let zDest = figure[pos * 3 + 2];

      // La direccion de los puntos se determina con el vector unitario de la distancia
      // hacia su destino
      let modulo = Math.sqrt(
        (xDest - xInicial) ** 2 +
          (yDest - yInicial) ** 2 +
          (zDest - zInicial) ** 2
      );
      let vecUnitario = [
        (xDest - xInicial) / modulo,
        (yDest - yInicial) / modulo,
        (zDest - zInicial) / modulo,
      ];

      this.particlesData[pos].velocity.x =
        vecUnitario[0] * this.particlesData[pos].v;
      this.particlesData[pos].velocity.y =
        vecUnitario[1] * this.particlesData[pos].v;
      this.particlesData[pos].velocity.z =
        vecUnitario[2] * this.particlesData[pos].v;

      this.particlesFinalPlace[pos * 3] = figure[pos * 3];
      this.particlesFinalPlace[pos * 3 + 1] = figure[pos * 3 + 1];
      this.particlesFinalPlace[pos * 3 + 2] = figure[pos * 3 + 2];
    },
    goToShape(figureName) {
      let figure = this.shapes.get(figureName);
      // Reiniciar parametro inPlace
      /*for (let i = 0; i < this.particlesData.length; i++) {
        this.particlesData[i].inPlace = 0;
      }*/

      // Velocidad aleatoria y DIRECCION para cada punto
      for (let i = 0; i <= this.vertices.length / 3; i++) {
        this.getDirection(i, figure);
      }

      // Cambio de variables
      /*for (let i = 0; i < this.particlesFinalPlace.length; i++) {
        this.particlesFinalPlace[i] = figure[i];
      }*/
    },
    updateParticles() {
      if (this.particlesData && this.pointCloud) {
        this.linearMove();

        /*// Porcentaje de llegada
        let sum = 0;
        for (let i = 0; i < this.particlesData.length; i++) {
          sum += this.particlesData[i].inPlace;
        }
        let percentage = (sum * 100) / this.particlesData.length;
        if (percentage > 99.9) {
          for (let i = 0; i < this.particlesData.length; i++) {
            this.particlesData[i].inPlace = 0;
          }
          if (this.particlesState == "expanded") {
            this.goToShape("cube");
            this.particlesState = "brain";
          } else if (this.particlesState == "brain") {
            this.goToShape("brain");
            this.particlesState = "finished";
          } else {
            this.particlesState = "idle";
          }
        }

        
        */
        this.pointCloud.geometry.attributes.position.needsUpdate = true;
      }
    },

    linearMove() {
      for (let i = 0; i < this.vertices.length / 3; i++) {
        // Define los limites de recorrido
        let range = 1;
        let dx =
          this.particlePositions[i * 3] - this.particlesFinalPlace[i * 3];
        let dy =
          this.particlePositions[i * 3 + 1] -
          this.particlesFinalPlace[i * 3 + 1];
        let dz =
          this.particlePositions[i * 3 + 2] -
          this.particlesFinalPlace[i * 3 + 2];

        let distance = Math.sqrt(dx ** 2 + dy ** 2 + dz ** 2);

        if (distance < range) {
          /*           if (this.particlesData[i].change) {
            this.particlesData[i].selectedPoint =
              this.particlesData[i].selectedPoint + 1;
            this.particlesData[i].selectedPoint = false;
          }
          // Actualizar direccion hacia el nuevo punto
          if (this.particlesData[i].selectedPoint < this.trajectory.length) {

            this.getDirection(
              i,
              this.shapes.get(
                this.trajectory[this.particlesData[i].selectedPoint * 1]
              )
            );
          } else { */
          // Si se acaba la trayectoria, el punto esta en su lugar
          this.particlesData[i].velocity.x = 0;
          this.particlesData[i].velocity.y = 0;
          this.particlesData[i].velocity.z = 0;
          this.particlesData[i].inPlace = 1;
          //}

          // Hack
          this.particlePositions[i * 3] = this.particlesFinalPlace[i * 3];
          this.particlePositions[i * 3 + 1] = this.particlesFinalPlace[
            i * 3 + 1
          ];
          this.particlePositions[i * 3 + 2] = this.particlesFinalPlace[
            i * 3 + 2
          ];
        } else {
          this.particlesData[i].selectedPoint = true;
        }
        /*if (distance > 500) {
          this.particlesData[i].velocity.x = 0;
          this.particlesData[i].velocity.y = 0;
          this.particlesData[i].velocity.z = 0;
        }*/

        // Actualizacion de posicion
        this.particlePositions[i * 3] += this.particlesData[i].velocity.x;
        this.particlePositions[i * 3 + 1] += this.particlesData[i].velocity.y;
        this.particlePositions[i * 3 + 2] += this.particlesData[i].velocity.z;
      }
    },

    /*
    f1(t) {
      let offsetx = -10;
      let offsety = -10;
      let offsetz = 0;
      let x = 10 * (Math.cos(3 * t) * Math.cos(t)) + offsetx;
      let y = 15 * (Math.cos(3 * t) * Math.sin(t)) + offsety;
      let z = 0 + offsetz; //15 * (Math.sin(3 * t) * Math.cos(t));
      return [x, y, z, offsetx, offsety, offsetz];
    },
    f2(t) {
      let offsetx = -10;
      let offsety = 0;
      let offsetz = 0;
      let x = 15 * (Math.cos(3 * t) * Math.cos(t)) + offsetx;
      let y = 20 * (Math.cos(3 * t) * Math.sin(t)) + offsety;
      let z = 0 + offsetz; //10 * (Math.sin(3 * t) * Math.cos(t));
      return [x, y, z, offsetx, offsety, offsetz];
    },
    f3(t) {
      let offsetx = -10;
      let offsety = 10;
      let offsetz = 0;
      let x = 15 * (Math.cos(3 * t) * Math.cos(t)) + offsetx;
      let y = 25 * (Math.cos(3 * t) * Math.sin(t)) + offsety;
      let z = 0 + offsetz; //5 * (Math.sin(3 * t) * Math.cos(t));
      return [x, y, z, offsetx, offsety, offsetz];
    },
    f4(t) {
      let offsetx = -15;
      let offsety = 10;
      let offsetz = 0;
      let x = 5 * (Math.cos(3 * t) * Math.cos(t)) + offsetx;
      let y = 5 * (Math.cos(3 * t) * Math.sin(t)) + offsety;
      let z = 0 + offsetz; //5 * (Math.sin(3 * t) * Math.cos(t));
      return [x, y, z, offsetx, offsety, offsetz];
    },
    f5(t) {
      let offsetx = -15;
      let offsety = 0;
      let offsetz = 0;
      let x = 6 * (Math.cos(3 * t) * Math.cos(t)) + offsetx;
      let y = 6 * (Math.cos(3 * t) * Math.sin(t)) + offsety;
      let z = 0 + offsetz; //5 * (Math.sin(3 * t) * Math.cos(t));
      return [x, y, z, offsetx, offsety, offsetz];
    },

    parametric() {
      var vertices = this.vertices;
      var particlesData = this.particlesData;
      var particlePositions = this.particlePositions;
      var start = this.start;

      for (let i = 0; i < vertices.length / 3; i++) {
        // Calcular nueva t
        let tFinal = Math.PI / 6; // + Math.PI / 6;
        if (particlesData[i].t < tFinal) {
          particlesData[i].t = particlesData[i].t + particlesData[i].dt;
        } else {
          particlesData[i].t = tFinal;
        }

        // Calcular xyz de cada punto dado t
        let xyz_off = []; //this.f(particlesData[i].t);
        if (i < 200) {
          xyz_off = this.f1(particlesData[i].t);
        }
        if (i >= 200 && i < 400) {
          xyz_off = this.f2(particlesData[i].t);
        }
        if (i >= 400 && i < 800) {
          xyz_off = this.f3(particlesData[i].t);
        }
        if (i >= 800 && i < 1400) {
          xyz_off = this.f4(particlesData[i].t);
        }
        if (i >= 1400) {
          xyz_off = this.f5(particlesData[i].t);
        }
        let percentage = (particlesData[i].t / tFinal) ** 11;

        // Actualizacion de posicion
        particlePositions[i * 3] =
          (start[i * 3] - xyz_off[3]) * percentage + xyz_off[0];
        particlePositions[i * 3 + 1] =
          (start[i * 3 + 1] - xyz_off[4]) * percentage + xyz_off[1];
        particlePositions[i * 3 + 2] =
          (start[i * 3 + 2] - xyz_off[5]) * percentage + xyz_off[2];
      }
    },*/
    showVideo1(id) {
      //let video;
      let texture;
      let geometry;
      let material;
      let mesh;
      this.video1 = document.getElementById("video");

      texture = new THREE.VideoTexture(this.video1);
      geometry = new THREE.PlaneGeometry(20, 10, 10);
      /*       geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(new Float32Array([1, 1, 1]), 3)
      ); */
      //geometry.positions.set(1, 1, 1);
      material = new THREE.MeshBasicMaterial({ map: texture });
      mesh = new THREE.Mesh(geometry, material);
      mesh.position.x = 30;
      mesh.position.y = -5;
      mesh.position.z = 25;

      this.scene.add(mesh);

      let self = this;
      const startButton = document.getElementById("video1-btn");

      startButton.addEventListener("click", function () {
        self.goToShape("screen");
        self.video1.play();
        /*         self.video1.addEventListener("play", function () {
          this.currentTime = 3;
        }); */
      });
    },

    onResize() {
      this.goToPosition(
        this.goToPositionValues.x,
        this.goToPositionValues.y,
        this.goToPositionValues.zoom,
        this.goToPositionValues.centerx,
        this.goToPositionValues.centery,
        this.goToPositionValues.centerz,
        this.goToPositionValues.sx,
        this.goToPositionValues.sy,
        this.goToPositionValues.szoom,
        this.goToPositionValues.scenterx,
        this.goToPositionValues.scentery,
        this.goToPositionValues.scenterz
      );
    },
    activateBrain(active) {
      this.activeBrain = active;
    },
    activateRobot(active) {
      this.activeRobot = active;
      this.controls.enabled = !active;
    },
    configPostProcessing() {
      this.composer = new EffectComposer(this.renderer);

      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      const afterimagePass = new AfterimagePass();
      this.composer.addPass(afterimagePass);

      console.log(afterimagePass.uniforms["damp"]);
    },
    updatePostProcessing() {
      this.composer.render();
    },
  },
  mounted() {
    if (WEBGL.isWebGLAvailable()) {
      this.configScene();
      this.configCameraConstrols();
      //this.configRaycaster();
      this.configHemisphereLights();
      this.configPointLights();
      this.configObjMtlModel();
      //this.configFbxModel();
      this.configGlbModel();
      //this.configColladaModel();
      //this.configCubeSprite();
      this.configBackgroud360Images();
      this.configMoves();
      //this.showVideo();
      //this.configPostProcessing();
      this.configRender();
    } else {
      this.$store.state.model3d.loading.brain = 100;
      this.$store.state.model3d.loading.robot = 100;
      this.$store.state.model3d.loading.back360 = 100;
      const warning = WEBGL.getWebGLErrorMessage();
      document.getElementById("brain-scene-container").appendChild(warning);
      /*       document.getElementById("brain-scene-container").style.backgroundImage =
        "url('/images/back4.jpg')";
      document.getElementById("brain-scene-container").style.backgroundSize =
        "cover"; */
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$store.state.model3d.goToPosition.activate": function (activate) {
      if (activate) {
        const self = this;
        self.goToPositionValues = self.$store.state.model3d.goToPosition;
        if (self.percentage == 100) {
          self.goToPosition(
            self.goToPositionValues.x,
            self.goToPositionValues.y,
            self.goToPositionValues.zoom,
            self.goToPositionValues.centerx,
            self.goToPositionValues.centery,
            self.goToPositionValues.centerz,
            self.goToPositionValues.sx,
            self.goToPositionValues.sy,
            self.goToPositionValues.szoom,
            self.goToPositionValues.scenterx,
            self.goToPositionValues.scentery,
            self.goToPositionValues.scenterz
          );
        } else {
          var timer = setInterval(function () {
            if (self.percentage == 100) {
              self.goToPosition(
                self.goToPositionValues.x,
                self.goToPositionValues.y,
                self.goToPositionValues.zoom,
                self.goToPositionValues.centerx,
                self.goToPositionValues.centery,
                self.goToPositionValues.centerz,
                self.goToPositionValues.sx,
                self.goToPositionValues.sy,
                self.goToPositionValues.szoom,
                self.goToPositionValues.scenterx,
                self.goToPositionValues.scentery,
                self.goToPositionValues.scenterz
              );
              clearInterval(timer);
            }
          }, 1000);
        }
        self.goToPositionValues.activate = false;
      }
    },
    "$store.state.model3d.changeBackgroudVideo.activate": function (activate) {
      if (activate) {
        const values = this.$store.state.model3d.changeBackgroudVideo;
        this.changeBackgroudVideo(values.src);
        values.activate = false;
      }
    },
    "$store.state.model3d.changeBackgroud360Image.activate": function (
      activate
    ) {
      if (activate) {
        const values = this.$store.state.model3d.changeBackgroud360Image;
        this.changeBackgroud360Image(values.index);
        values.activate = false;
      }
    },
    "$store.state.model3d.changeBaseColor.activate": function (activate) {
      if (activate) {
        const values = this.$store.state.model3d.changeBaseColor;
        this.changeBaseColor(values.h, values.s, values.l);
        values.activate = false;
      }
    },
    "$store.state.model3d.activateBrain.activate": function (activate) {
      if (activate) {
        const values = this.$store.state.model3d.activateBrain;
        this.activateBrain(values.active);
        values.activate = false;
      }
    },
    "$store.state.model3d.activateRobot.activate": function (activate) {
      if (activate) {
        const values = this.$store.state.model3d.activateRobot;
        this.activateRobot(values.active);
        values.activate = false;
      }
    },
    "$store.state.model3d.goToShape.activate": function (activate) {
      if (activate) {
        const self = this;
        const values = self.$store.state.model3d.goToShape;
        if (self.percentage == 100) {
          self.goToShape(values.figureName);
        } else {
          var timer = setInterval(function () {
            if (self.percentage == 100) {
              self.goToShape(values.figureName);
              clearInterval(timer);
            }
          }, 500);
        }
        values.activate = false;
      }
    },
    "$store.state.video.activate": function (activate) {
      if (activate) {
        this.videoActive = this.$store.state.video.active;
        this.$store.state.video.activate = false;
      }
    },
  },
  computed: {
    percentage() {
      return (
        (this.$store.state.model3d.loading.brain +
          this.$store.state.model3d.loading.robot +
          this.$store.state.model3d.loading.back360) /
        3
      );
    },
  },
};
</script>

<style>
#brain-scene-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.brain-scene-no-events {
  pointer-events: none;
}

.front-video-test {
  position: absolute;
}
</style>
