<template>
  <div class="AI">
    <InfoViewer
      :top="10"
      :left="40"
      :width="20"
      :height="0"
      :stop="5"
      :sleft="20"
      :swidth="60"
      :sheight="0"
    >
    </InfoViewer>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToServices"></Button>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import SidebarAI from "../organisms/SidebarAI";

export default {
  name: "AI",
  components: { Button, InfoViewer, SidebarAI },
  props: {},
  methods: {
    goToIA1() {
      this.$router.push({ name: "ai1" });
    },
    goToIA2() {
      this.$router.push({ name: "ai2" });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: 1.1,
      y: 1.1,
      zoom: 40,
      centerx: 0,
      centery: 5,
      centerz: 0,
      sx: 1,
      sy: 1,
      szoom: 30,
      scenterx: 0,
      scentery: 8,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 174, s: 1, l: 0.44 });
    this.$store.commit("changeBackgroud360Image", { index: 1 });
    this.$store.commit("activateBrain", { active: true });
    this.$store.commit("activateRobot", { active: false });
    this.$store.commit("updateVideoVisibility", { active: false });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: true });
    this.$store.commit("changeSlideBarIDState", { value: false });
    this.$store.commit("changeSlideBarAIColor", { color: "#FFFFFF" });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false
      }
    }
  }
};
</script>
<style>
.AI {
  color: white;
}
</style>
