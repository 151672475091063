<template>
  <div class="image1">
    <img class="image1" :src="publicPath + 'images/' +  src">
  </div>
</template>

<script>
export default {
  name: "Image1",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    src: String,
  },
};
</script>

<style>
.image1 {
  width: 100%;
}
</style>
