<template>
  <div class="services">
    <div class="services-content">
      <div class="services-center-button-1" @mouseover="onAIMouseOver" @mouseout="onMouseOut">
        <Button :text="'Inteligencia artificial'" :styleType="1" v-on:click="goToIA"></Button>
      </div>
      <div class="services-center-button-1" @mouseover="onIDMouseOver" @mouseout="onMouseOut">
        <Button :text="'Diseño interactivo'" :styleType="2" v-on:click="goToID"></Button>
      </div>
    </div>
  </div>
</template>
 
<script>
import Button from "../atoms/Button"; 
import InfoViewer from "../organisms/InfoViewer";

export default {
  name: "Services",
  components: {Button, InfoViewer},
  props: {},
  methods: {
    goToIA() {
      this.$router.push({ name: 'ai' });
    },
    goToID() {
      this.$router.push({ name: 'id' });
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    onAIMouseOver() {
      this.$store.commit("changeBaseColor", { h: 174, s: 1, l: 0.44 });
    },
    onIDMouseOver() {
      this.$store.commit("changeBaseColor", { h: -(360 - 326), s: 0.74, l: 0.52 });
    },
    onMouseOut() {
      this.$store.commit("changeBaseColor", { h: 0, s: 0, l: 1});
    }
  },
  mounted() {
    this.$store.commit("goToPosition", { x: 0, y: Math.PI / 2, zoom: 50, centerx: 20, centery: 10, centerz: 0, sx: 0, sy: Math.PI / 2, szoom: 50, scenterx: 20, scentery: 0, scenterz: 0 });
    this.$store.commit("changeBaseColor", { h: 0, s: 0, l: 1});
    this.$store.commit("changeBackgroud360Image", { index: 0});
    this.$store.commit("activateBrain", { active: true });
    this.$store.commit("activateRobot", { active: false });
    this.$store.commit("updateVideoVisibility", { active: false });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    this.$store.commit("changeSlideBarIDState", { value: false });
  }
};
</script>
<style>
.services {
  color: white;
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.services-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
}

@media screen and (max-width: 700px) {
  .services-content {
    flex-direction: column;
    margin-top: auto;
    padding-bottom: 5vh;
  }
}

.services-center-button-1 {
  width: 220px;
  margin: 5px;
}

@media screen and (max-width: 700px) {
  .services-center-button-1 {
    
  }
}

.services-center-button-2 {
  width: 220px;
}

@media screen and (max-width: 700px) {
  .services-center-button-2 {
    
  }
}

.services-space {
  margin-bottom: 10px;
}

</style>
