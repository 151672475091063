<template>
  <div class="header">
    <div class="header-logo">
      <Logo></Logo>
    </div>
    <div v-if="innerWidth >= 700" class="header-buttons">
      <div class="header-button">
        <Button
          :text="'Contacto'"
          :styleType="6"
          v-on:click="goToContact"
        ></Button>
      </div>
    </div>
    <div v-else class="header-buttons">
      <div class="header-button">
        <Button
          :text="'Menu'"
          :styleType="6"
          v-on:click="changeMenuState"
        ></Button>
      </div>
    </div>
    <div v-if="$store.state.changeMenuState.value">
      <Menu></Menu>
    </div>
  </div>
</template>

<script>
import Logo from "./../atoms/Logo.vue";
import TabButton from "./../atoms/TabButton";
import Button from "./../atoms/Button";
import Menu from "./Menu";

export default {
  name: "Header",
  data() {
    return {
      innerWidth: window.innerWidth
    }
  },
  components: {
    Logo,
    TabButton,
    Button,
    Menu
  },
  methods: {
    goToContact() {
      this.$router.push({ name: "contact" });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },
    changeMenuState() {
      this.$store.commit("changeMenuState", { value: !this.$store.state.changeMenuState.value });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
.header {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 80px;
  overflow: hidden;
}
.header-logo {
  display: inline-block;
  padding: 17px;
}
.header-buttons {
  margin-left: auto; 
  margin-right: 15px;
}
.header-button {
  display: inline-block;
  margin-top: -5px;
}
</style>