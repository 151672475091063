<template>
  <div class="unnamed-character-style-3 subtitle2">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Subitulo2",
  props: {
    text: String,
  },
};
</script>

<style>
.subtitle2 {
  color: rgb(69, 68, 68) !important;
}
</style>
