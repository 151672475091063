// Modulos instalados
import Vue from "vue";
import Vuex from "vuex";

// Usar Vuex
Vue.use(Vuex);

// Estados del sistema
const state = {
  model3d: {
    goToPosition: {
      activate: false,
      x: 0,
      y: 0,
      zoom: 0,
      centerx: 0,
      centery: 0,
      centerz: 0,
      sx: 0,
      sy: 0,
      szoom: 0,
      scenterx: 0,
      scentery: 0,
      scenterz: 0,
    },
    changeBackgroudVideo: {
      activate: false,
      src: "",
    },
    changeBackgroud360Image: {
      activate: false,
      index: 0,
    },
    changeBaseColor: {
      activate: false,
      h: 0,
      s: 0,
      l: 0,
    },
    activateBrain: {
      activate: false,
      active: true,
    },
    activateRobot: {
      activate: false,
      active: true,
    },
    goToShape: {
      figureName: false,
      active: true,
    },
    loading: {
      brain: 0,
      robot: 0,
      back360: 0,
      particles: 0,
    },
  },
  video: {
    activate: false,
    active: false,
  },
  changeMenuState: {
    activate: false,
    value: false
  },
  changeSlideBarAIState: {
    activate: false,
    value: false
  },
  changeSlideBarIDState: {
    activate: false,
    value: false
  },
  updateSameView: {
    activate: false
  },
  changeSlideBarAIColor: {
    activate: false,
    color: "#ffffff"
  },
  changeSlideBarIDColor: {
    activate: false,
    color: "#ffffff"
  },
};

// Funciones que gestionan los estados globalmente
export const mutations = {
  goToPosition(state, values) {
    if (values) {
      state.model3d.goToPosition = {
        activate: true,
        x: values.x,
        y: values.y,
        zoom: values.zoom,
        centerx: values.centerx,
        centery: values.centery,
        centerz: values.centerz,
        sx: values.sx,
        sy: values.sy,
        szoom: values.szoom,
        scenterx: values.scenterx,
        scentery: values.scentery,
        scenterz: values.scenterz,
      };
    } else {
      state.model3d.goToPosition.activate = true;
    }
  },
  changeBackgroudVideo(state, { src }) {
    state.model3d.changeBackgroudVideo = { activate: true, src };
  },
  changeBackgroud360Image(state, { index }) {
    state.model3d.changeBackgroud360Image = { activate: true, index };
  },
  changeBaseColor(state, { h, s, l }) {
    state.model3d.changeBaseColor = { activate: true, h, s, l };
  },
  setBrainLoading(state, { value }) {
    state.model3d.loading.brain = value;
  },
  setRobotLoading(state, { value }) {
    state.model3d.loading.robot = value;
  },
  setBack360Loading(state, { value }) {
    state.model3d.loading.back360 = value;
  },
  activateBrain(state, { active }) {
    state.model3d.activateBrain = { activate: true, active };
  },
  activateRobot(state, { active }) {
    state.model3d.activateRobot = { activate: true, active };
  },
  goToShape(state, { figureName }) {
    state.model3d.goToShape = { activate: true, figureName };
  },
  updateVideoVisibility(state, { active }) {
    state.video = { activate: true, active };
  },
  changeMenuState(state, { value }) {
    state.changeMenuState = { activate: true, value };
  },
  changeSlideBarAIState(state, { value }) {
    state.changeSlideBarAIState = { activate: true, value };
  },
  changeSlideBarIDState(state, { value }) {
    state.changeSlideBarIDState = { activate: true, value };
  },
  updateSameView(state) {
    state.updateSameView = { activate: true };
  },
  changeSlideBarAIColor(state, { color }) {
    state.changeSlideBarAIColor = { activate: true, color };
  },
  changeSlideBarIDColor(state, { color }) {
    state.changeSlideBarIDColor = { activate: true, color };
  }
};

// Exportar almacenamiento global centralizado
export default new Vuex.Store({
  state: state,
  mutations: mutations,
});
