<template>
  <div class="logo" v-on:click="goToHome">
    <img src="../../assets/logo.png" />
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
  }
};
</script>

<style></style>
