var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:_vm.styleType == 1
      ? 'button-type-1'
      : _vm.styleType == 2
      ? 'button-type-2'
      : _vm.styleType == 3
      ? 'button-type-3'
      : _vm.styleType == 4
      ? 'button-type-4'
      : _vm.styleType == 5
      ? 'button-type-5'
      : 'button-type-6',on:{"click":_vm.onClick}},[_c('div',{staticClass:"button-content"},[(_vm.styleType == 4)?_c('div',{staticClass:"button-icon-content"},[_c('div',{staticClass:"button-icon"},[_c('Icon',{attrs:{"src":'arrow.png'}})],1)]):_vm._e(),(_vm.styleType == 6 && _vm.innerWidth < 700)?_c('div',{staticClass:"button-icon-content"},[_c('div',{staticClass:"button-icon"},[_c('Icon',{attrs:{"src":'hamburger.png'}})],1)]):_vm._e(),_c('span',{staticClass:"button-type-text"},[_vm._v(_vm._s(_vm.text.toUpperCase()))])])])}
var staticRenderFns = []

export { render, staticRenderFns }