<template>
  <div>
    <h1>Hola mundo</h1>
    <div id="scene-container"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "../../vendor/treejs/OrbitControls";

export default {
  name: "Three",
  methods: {
    drawCube() {
      // Get a reference to the container element that will hold our scene
      const container = document.querySelector("#scene-container");

      // create a Scene
      const scene = new THREE.Scene();

      // Set the background color
      scene.background = new THREE.Color("skyblue");

      // Create a camera
      const fov = 35; // AKA Field of View
      const aspect = container.clientWidth / container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 100; // the far clipping plane

      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

      // every object is initially created at ( 0, 0, 0 )
      // move the camera back so we can view the scene
      camera.position.set(3, 5, 20);
      camera.rotation.set(-0.3, 0, 0);
      // create a geometry
      const geometry = new THREE.BoxBufferGeometry(2, 2, 2);

      // create a default (white) Basic material
      const material = new THREE.MeshStandardMaterial({ color: "purple" });

      // create a Mesh containing the geometry and material
      const cube = new THREE.Mesh(geometry, material);

      // add the mesh to the scene
      scene.add(cube);

      // create the renderer
      const renderer = new THREE.WebGLRenderer();

      renderer.physicallyCorrectLights = true;

      const light = new THREE.DirectionalLight("white", 8);

      light.position.set(10, 10, 10);

      scene.add(light);

      // next, set the renderer to the same size as our container element
      renderer.setSize(container.clientWidth, container.clientHeight);

      // finally, set the pixel ratio so that our scene will look good on HiDPI displays
      renderer.setPixelRatio(window.devicePixelRatio);

      // add the automatically created <canvas> element to the page
      container.append(renderer.domElement);

      // render, or 'create a still image', of the scene
      renderer.render(scene, camera);

      const controls = new OrbitControls(camera, renderer.domElement);

      function animate() {
        controls.update();

        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      }
      animate();
    },
    drawParticlesAndLines() {
      let group;
      let container;
      const particlesData = [];
      let camera, scene, renderer;
      let positions, colors;
      let particles;
      let pointCloud;
      let particlePositions;
      let linesMesh;
      let velocity = 0.1;
      const effectController = {
        showDots: true,
        showLines: true,
        minDistance: 3,
        limitConnections: false,
        maxConnections: 5,
        particleCount: 150,
      };

      //const maxParticleCount = 550;
      let particleCount = 100;
      const r = 30;
      const rHalf = r / 2;
      init();
      animate();

      function init() {
        container = document.querySelector("#scene-container");

        // Create a camera
        const fov = 45; // AKA Field of View
        const aspect = container.clientWidth / container.clientHeight;
        const near = 1; // the near clipping plane
        const far = 5000; // the far clipping plane

        camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        camera.position.set(0, 10, 80);

        const controls = new OrbitControls(camera, container);
        controls.minDistance = 10;
        controls.maxDistance = 100;

        scene = new THREE.Scene();

        group = new THREE.Group();

        scene.add(group);

        const helper = new THREE.BoxHelper(
          new THREE.Mesh(new THREE.BoxGeometry(r, r, r))
        );
        helper.material.color.setHex(0x101010);
        helper.material.blending = THREE.AdditiveBlending;
        helper.material.transparent = true;
        group.add(helper);

        const segments = particleCount * particleCount;

        positions = new Float32Array(segments*3);
        colors = new Float32Array(segments * 3);

        const pointsMaterial = new THREE.PointsMaterial({
          color: 0xffffff,
          size: 3,
          blending: THREE.AdditiveBlending,
          transparent: true,
          sizeAttenuation: false,
        });

        particles = new THREE.BufferGeometry();
        particlePositions = new Float32Array(particleCount * 3);

        // Creacion de las particulas
        for (let i = 0; i <= particleCount; i++) {
          const x = Math.random() * r - r / 2;
          const y = Math.random() * r - r / 2;
          const z = Math.random() * r - r / 2;

          particlePositions[i * 3] = x;
          particlePositions[i * 3 + 1] = y;
          particlePositions[i * 3 + 2] = z;

          particlesData.push({
            velocity: new THREE.Vector3(
              Math.random() * velocity - velocity / 2,
              Math.random() * velocity - velocity / 2,
              Math.random() * velocity - velocity / 2
            ),
            numConnections: 0,
          });
        }

        particles.setDrawRange(0, particleCount);
        particles.setAttribute(
          "position",
          new THREE.BufferAttribute(particlePositions, 3).setUsage(
            THREE.DynamicDrawUsage
          )
        );

        // Crear sistema de particulas
        pointCloud = new THREE.Points(particles, pointsMaterial);
        group.add(pointCloud);

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(positions, 3).setUsage(
            THREE.DynamicDrawUsage
          )
        );
        geometry.setAttribute(
          "color",
          new THREE.BufferAttribute(colors, 3).setUsage(THREE.DynamicDrawUsage)
        );

        geometry.computeBoundingSphere(); // (????) verificar si esto sirve para algo
        geometry.setDrawRange(0, 0);

        // Material para las lineas
        const material = new THREE.LineBasicMaterial({
          vertexColors: true,
          blending: THREE.AdditiveBlending,
          transparent: true,
        });

        linesMesh = new THREE.LineSegments(geometry, material);
        group.add(linesMesh);

        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild(renderer.domElement);
        window.addEventListener("resize", onWindowResize);
      }

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }

      function animate() {
        let vertexpos = 0;
        let colorpos = 0;
        let numConnected = 0;

        for (let i = 0; i < particleCount; i++)
          particlesData[i].numConnections = 0;

        for (let i = 0; i < particleCount; i++) {
          // get the particle
          const particleData = particlesData[i];

          particlePositions[i * 3] += particleData.velocity.x;
          particlePositions[i * 3 + 1] += particleData.velocity.y;
          particlePositions[i * 3 + 2] += particleData.velocity.z;

          // Define los limites de recorrido dentro del cubo

          if (
            particlePositions[i * 3 + 1] < -rHalf ||
            particlePositions[i * 3 + 1] > rHalf
          )
            particleData.velocity.y = -particleData.velocity.y;

          if (
            particlePositions[i * 3] < -rHalf ||
            particlePositions[i * 3] > rHalf
          )
            particleData.velocity.x = -particleData.velocity.x;

          if (
            particlePositions[i * 3 + 2] < -rHalf ||
            particlePositions[i * 3 + 2] > rHalf
          )
            particleData.velocity.z = -particleData.velocity.z;

          if (
            effectController.limitConnections &&
            particleData.numConnections >= effectController.maxConnections
          )
            continue;

          // Check collision
          for (let j = i + 1; j < particleCount; j++) {
            const particleDataB = particlesData[j];
            if (
              effectController.limitConnections &&
              particleDataB.numConnections >= effectController.maxConnections
            )
              continue;

            const dx = particlePositions[i * 3] - particlePositions[j * 3];
            const dy =
              particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1];
            const dz =
              particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2];
            const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

            if (dist < effectController.minDistance) {
              particleData.numConnections++;
              particleDataB.numConnections++;

              const alpha = 1.0 - dist / effectController.minDistance;

              positions[vertexpos++] = particlePositions[i * 3];
              positions[vertexpos++] = particlePositions[i * 3 + 1];
              positions[vertexpos++] = particlePositions[i * 3 + 2];

              positions[vertexpos++] = particlePositions[j * 3];
              positions[vertexpos++] = particlePositions[j * 3 + 1];
              positions[vertexpos++] = particlePositions[j * 3 + 2];

              colors[colorpos++] = alpha;
              colors[colorpos++] = alpha;
              colors[colorpos++] = alpha;

              colors[colorpos++] = alpha;
              colors[colorpos++] = alpha;
              colors[colorpos++] = alpha;

              numConnected++;
            }
          }
        }

        linesMesh.geometry.setDrawRange(0, numConnected * 2);
        linesMesh.geometry.attributes.position.needsUpdate = true;
        linesMesh.geometry.attributes.color.needsUpdate = true;

        pointCloud.geometry.attributes.position.needsUpdate = true;

        requestAnimationFrame(animate);
        render();
      }

      function render() {
        renderer.render(scene, camera);
      }
    },

    drawParticles() {
      const container = document.querySelector("#scene-container");

      let camera, scene, renderer, stats;

      let mesh;
      const amount = parseInt(window.location.search.substr(1)) || 10;
      const count = Math.pow(amount, 3);

      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2(1, 1);

      const color = new THREE.Color();

      init();
      animate();

      function init() {
        camera = new THREE.PerspectiveCamera(
          60,
          container.clientWidth / container.clientHeight,
          0.1,
          100
        );
        camera.position.set(amount, amount, amount);
        camera.lookAt(0, 0, 0);

        scene = new THREE.Scene();

        const light1 = new THREE.HemisphereLight(0xffffff, 0x000088);
        light1.position.set(-1, 1.5, 1);
        scene.add(light1);

        const light2 = new THREE.HemisphereLight(0xffffff, 0x880000, 0.5);
        light2.position.set(-1, -1.5, -1);
        scene.add(light2);

        const geometry = new THREE.IcosahedronGeometry(0.5, 3);
        const material = new THREE.MeshPhongMaterial();

        mesh = new THREE.InstancedMesh(geometry, material, count);

        let i = 0;
        const offset = (amount - 1) / 2;

        const matrix = new THREE.Matrix4();

        for (let x = 0; x < amount; x++) {
          for (let y = 0; y < amount; y++) {
            for (let z = 0; z < amount; z++) {
              matrix.setPosition(offset - x, offset - y, offset - z);

              mesh.setMatrixAt(i, matrix);
              mesh.setColorAt(i, color);

              i++;
            }
          }
        }

        scene.add(mesh);

        //

        //const gui = new GUI();
        //gui.add(mesh, "count", 0, count);

        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        container.append(renderer.domElement);

        new OrbitControls(camera, renderer.domElement);

        //stats = new Stats();
        //document.body.appendChild(stats.dom);

        window.addEventListener("resize", onWindowResize);
        document.addEventListener("mousemove", onMouseMove);
      }

      function onWindowResize() {
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(container.clientWidth / container.clientHeight);
      }

      function onMouseMove(event) {
        event.preventDefault();

        mouse.x = (event.clientX / container.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / container.clientHeight) * 2 + 1;
      }

      function animate() {
        requestAnimationFrame(animate);

        render();
      }

      function render() {
        raycaster.setFromCamera(mouse, camera);

        const intersection = raycaster.intersectObject(mesh);

        if (intersection.length > 0) {
          const instanceId = intersection[0].instanceId;

          mesh.setColorAt(instanceId, color.setHex(Math.random() * 0xffffff));
          mesh.instanceColor.needsUpdate = true;
        }

        renderer.render(scene, camera);

        //stats.update();
      }
    },


  },
  mounted() {
    this.drawParticlesAndLines();
  },
};
</script>

<style>
#scene-container {
  position: absolute;
  width: 100%;
  height: 100%;

  /*
    Set the container's background color to the same as the scene's
    background to prevent flashing on load
  */
  /* background-color: rgb(0, 0, 0); */
}
</style>
