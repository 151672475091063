<template>
  <div class="character-style-3 description2">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Description2",
  props: {
    text: String,
  },
};
</script>

<style>
.description2 {
  color: rgb(177, 177, 177) !important;
}
</style>
