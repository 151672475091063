<template>
  <div>
    <div class="input-label"><Description2 :text="text"></Description2></div>
    &nbsp;
    <div class="input-label input-label-dot">{{ obligatory ? "*" : "" }}</div>
  </div>
</template>

<script>
import Description2 from "../atoms/Description2";
export default {
  name: "InputLabel",
  props: {
    text: String,
    obligatory: Boolean,
  },
  components: {
    Description2,
  },
  methods: {},
};
</script>

<style scoped>
.input-label {
  display: inline-block;
}
.input-label-dot {
  color: #1468e6;
}
</style>
