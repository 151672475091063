import Vue from "vue";
import VueRouter from 'vue-router';
import Home from "../components/Pages/Home.vue";
import Contact from "../components/Pages/Contact.vue";
import Services from "../components/Pages/Services.vue";
import AI from "../components/Pages/AI.vue";
import AI1 from "../components/Pages/AI1.vue";
import AI2 from "../components/Pages/AI2.vue";
import ID from "../components/Pages/ID.vue";
import ID1 from "../components/Pages/ID1.vue";
import ID2 from "../components/Pages/ID2.vue";
import ID3 from "../components/Pages/ID3.vue";
import ID4 from "../components/Pages/ID4.vue";
import ID5 from "../components/Pages/ID5.vue";
    
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: "/home",
        name: "home",
        component: Home
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact
    },
    {
        path: "/services",
        name: "services",
        component: Services
    },
    {
        path: "/services/artificial-intelligence",
        name: "ai",
        component: AI
    },
    {
        path: "/services/artificial-intelligence/reinforcement-learning",
        name: "ai1",
        component: AI1
    },
    {
        path: "/services/artificial-intelligence/neural-networks-and-deep-learning",
        name: "ai2",
        component: AI2
    },
    {
        path: "/services/interactive-design",
        name: "id",
        component: ID
    },
    {
        path: "/services/interactive-design/augmented-reality",
        name: "id1",
        component: ID1
    },
    {
        path: "/services/interactive-design/virtual-reality",
        name: "id2",
        component: ID2
    },
    {
        path: "/services/interactive-design/web-and-mobile-applications",
        name: "id3",
        component: ID3
    },
    {
        path: "/services/interactive-design/videogames",
        name: "id4",
        component: ID4
    },
    {
        path: "/services/interactive-design/virtual-production",
        name: "id5",
        component: ID5
    },
    {
    path: "*",
    redirect: "/home"
    },
];

const router = new VueRouter({
    mode: "history",
    routes
});

// Camputar error al ir a la misma página
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name === 'NavigationDuplicated') router.app.$store.commit("updateSameView");
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

export default router;