import Vue from "vue";
import Vuex from './storage/Vuex'
import App from "./App.vue";
import VueRouter from "./routes/VueRouter";

Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  "a-scene",
  "a-entity",
  "a-camera",
  "a-box",
  "a-sky",
  "a-sphere",
  "a-cylinder",
  "a-plane",
  "a-assets",
  "a-cursor",
  "<a-asset-item>",
];

Vue.use(Vuex);

new Vue({
  render: (h) => h(App),
  store: Vuex,
  router: VueRouter
}).$mount("#app");


