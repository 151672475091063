<template>
  <div>
    <input
      class="character-style-3 input-text"
      spellcheck="false"
      autocorrect="off"
      :placeholder="placeholder"
      v-on:keyup="valueFun"
      v-model="text"
    />
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: {
    placeholder: String,
    value: Function,
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    valueFun() {
      this.value(this.text);
    },
  },
};
</script>

<style>
.input-text {
  outline: 0;
  border-width: 0 0 2px;
  border-color: silver;
  color: rgb(177, 177, 177) !important;
  width: calc(100% - 40px);
  height: 40px;
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;

  background: #082039fa 0% 0% no-repeat padding-box;
  border: 2px solid #44b0b8;
  border-radius: 10px;
}

.input-text:focus {
  border-color: #5bf4ff;
  background: #0e3662fc;
}
</style>
