<template>
  <div class="AI1" id="AI1">
    <SwapArrows v-on:upClick="goToIA2" v-on:downClick="goToIA2"></SwapArrows>
    <div class="AI1-block">
      <div class="AI1-block-content">
        <div class="AI1-subtitle1 AI1-right">APRENDIZAJE</div>
        <div class="AI1-subtitle2 AI1-right">POR REFUERZO</div>
        <div class="AI1-space"></div>
        <div class="AI1-description">
          Desarrollamos e investigamos aplicaciones y soluciones basadas en:
          <b
            >Algoritmos Genéticos, DQN , ASC, SARSA, Q-Learning y Sistemas Multi
            Agentes (MAS).
          </b>
        </div>
      </div>
    </div>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToIA"></Button>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import Image1 from "../atoms/Image1";
import SidebarAI from "../organisms/SidebarAI";
import SwapArrows from "../molecules/SwapArrows";
import Swipe from "../../vendor/Swipe";

export default {
  name: "AI1",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { Button, InfoViewer, Image1, SidebarAI, SwapArrows },
  props: {},
  methods: {
    goToIA() {
      this.$router.push({ name: "ai" });
      this.$store.commit("goToShape", { figureName: "cube" });
    },
    goToIA2() {
      this.$router.push({ name: "ai2" });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 700) {
        this.$store.commit("changeSlideBarAIState", { value: false });
      } else {
        this.$store.commit("changeSlideBarAIState", { value: true });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: 0.35,
      y: 1.8,
      zoom: 40,
      centerx: 20,
      centery: 12,
      centerz: 0,
      sx: 0.35,
      sy: 1.8,
      szoom: 40,
      scenterx: 20,
      scentery: 12,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 174, s: 1, l: 0.44 });
    this.$store.commit("changeBackgroud360Image", { index: 1 });
    this.$store.commit("activateBrain", { active: false });
    this.$store.commit("activateRobot", { active: true });
    this.$store.commit("changeBackgroudVideo", { src: "aprendizaje.mp4" });
    this.$store.commit("updateVideoVisibility", { active: true });
    this.$store.commit("changeMenuState", { value: false });
    if (this.innerWidth < 700) {
      this.$store.commit("changeSlideBarAIState", { value: false });
    } else {
      this.$store.commit("changeSlideBarAIState", { value: true });
    }
    this.$store.commit("changeSlideBarIDState", { value: false });
    this.$store.commit("changeSlideBarAIColor", { color: "#34B6C0" });
    Swipe.detectSwipe("AI1", (el, dir) => {
      if (dir == "up") {
        this.$router.push({ name: "ai2" });
      } else if (dir == "down") {
        this.$router.push({ name: "ai2" });
      }
    });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false;
      }
    },
  },
};
</script>
<style>
.AI1 {
  color: white;
  width: 100%;
  height: 100%;
}
.AI1-title {
  text-align: center;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 300;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .AI1-title {
    font-size: 17px;
  }
}

.AI1-subtitle1 {
  margin-left: auto;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}

.AI2-subtitle1 {
  margin-right: auto;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
}

.AI1-right {
  text-align: right;
}

.AI1-space {
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .AI1-space {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 700px) {
  .AI1-subtitle1 {
    font-size: 17px;
    margin-top: auto;
  }

  .AI2-subtitle1 {
    font-size: 17px;
    margin-top: auto;
  }
}

.AI1-subtitle2 {
  margin-left: auto;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
}

.AI2-subtitle2 {
  margin-right: auto;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .AI1-subtitle2 {
    font-size: 17px;
    margin-top: auto;
  }

  .AI2-subtitle2 {
    font-size: 17px;
    margin-top: auto;
  }
}

.AI1-description {
  max-width: 300px;
  text-align: right;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 5px;
  line-height: 1.6;
  margin-left: auto;
}

.AI2-description {
  max-width: 300px;
  text-align: left;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 0px;
  line-height: 1.6;
  margin-right: auto;
}

@media screen and (max-width: 700px) {
  .AI1-description {
    font-size: 11px;
    margin-top: auto;
  }

  .AI2-description {
    font-size: 11px;
    margin-top: auto;
  }
}

.AI1-block {
  width: 100%;
  height: calc(100% - 100px);
  padding-right: 10vw;
  padding-bottom: 5vh;
  padding-left: 10vw;
  padding-top: 5vh;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  animation: 2s ease-out 0s 1 AI1BlockAppear;
}

@keyframes AI1BlockAppear {
  0% {
    transform: scaleY(0.9);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

.AI1-block-content {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.AI2-block-content {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .AI1-block-content {
    margin-top: auto;
  }
  .AI2-block-content {
    margin-top: auto;
  }
}
</style>
