<template>
  <div class="icon">
    <img class="icon" :src="publicPath + 'icons/' +  src">
  </div>
</template>

<script>
export default {
  name: "Icon",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    src: String,
  },
};
</script>

<style>
.icon {
  display: inline-block;
  width: 100%;
  opacity: 0.7;
  transition: 0.3s;
}

@media screen and (max-width: 700px) {
  .icon {
    opacity: 1;
  }
}

.icon:hover {
  color: #ffffff;
  transform: scale(1.05);
    opacity: 1;
}

</style>
