<template>
  <div class="sidebar-id" v-if="$store.state.changeSlideBarIDState.value">
    <ul class="character-style-5">
      <li>
        <span class="text-menu2" :style="styleText" v-on:click="goToID1">REALIDAD AUMENTADA</span>
        <span class="dot" :style="style"><span class="v-line2" :style="style"></span></span>
      </li>
      <li>
        <span class="text-menu2" :style="styleText" v-on:click="goToID2">REALIDAD VIRTUAL </span
        ><span class="dot" :style="style"><span class="v-line2" :style="style"></span></span>
      </li>
      <li>
        <span class="text-menu2" :style="styleText" v-on:click="goToID3">APLICACIONES WEB Y MÓVIL</span
        ><span class="dot" :style="style"><span class="v-line2" :style="style"></span></span>
      </li>
      <li>
        <span class="text-menu2" :style="styleText" v-on:click="goToID4"> VIDEOJUEGOS</span
        ><span class="dot" :style="style"><span class="v-line2" :style="style"></span></span>
      </li>
      <li id="video1-btn">
        <span class="text-menu2" :style="styleText" v-on:click="goToID5">VIRTUAL PRODUCTION</span
        ><span class="dot" :style="style"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarID",
  data() {
    return {
      style: {
        background: "#ffffff"        
      },
      styleText: {
        color: "#ffffff"        
      },
    }
  },
  methods: {
    goToID1() {
      this.$router.push({ name: "id1" });
    },
    goToID2() {
      this.$router.push({ name: "id2" });
    },
    goToID3() {
      this.$router.push({ name: "id3" });
    },
    goToID4() {
      this.$router.push({ name: "id4" });
    },
    goToID5() {
      this.$router.push({ name: "id5" });
    },
  },
  watch: {
    "$store.state.changeSlideBarIDColor.activate": function (activate) {
      if (activate) {
        this.style.background = this.$store.state.changeSlideBarIDColor.color;
        this.styleText.color = this.$store.state.changeSlideBarIDColor.color;
        this.$store.state.changeSlideBarIDColor.activate = false;
      }
    }
  }
};
</script>

<style>
@media screen and (min-width: 700px) {
  .sidebar-id {
    position: fixed;
    right: 5vw;
    top: 25vh;
    animation: 2s ease-out 0s 1 slideBarIDAppear1;
  }

  @keyframes slideBarIDAppear1 {
    0% {
      transform: scaleY(0.9);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

@media screen and (max-width: 700px) {
  .sidebar-id {
    position: fixed;
    left: calc(100vw - 500px);
    top: 12vh;
    animation: 2s ease-out 0s 1 slideBarIDAppear2;
    transform: scale(0.7);
  }

  @keyframes slideBarIDAppear2 {
    0% {
      transform: scale(0.6);
      opacity: 0;
    }
    100% {
      transform: scale(0.7);
      opacity: 1;
    }
  }
}

.sidebar-id ul {
  list-style: none;
  text-align: right;
}
.sidebar-id ul li {
  margin-bottom: 10vh;
  width: 510px;
}

@media screen and (max-width: 700px) {
  .sidebar-id ul li {
    margin-bottom: 5vh;
  }
}

.dot {
  height: 7px;
  width: 7px;
  margin-left: 0vw;
  background-color: #ffff;
  border-radius: 50%;
  display: inline-block;
}
.v-line2 {
  height: 11vh;
  width: 1px;
  margin: 7px 0 0 3px;
  background-color: #ffff;
  display: block;
}

@media screen and (max-width: 700px) {
  .v-line2 {
    height: 6vh;
  }
}

.text-menu2 {
  transition: 0.5s;
  opacity: 0.8;
  margin-right: 2vw;
}
.text-menu2:hover {
  font-size: 18px;
  opacity: 1;
  color: #ffffff !important;
}
</style>