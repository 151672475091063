<template>
  <div class="front">
    <Header></Header>
    <router-view></router-view>
    <SidebarAI></SidebarAI>
    <SidebarID></SidebarID>
  </div>
</template>
 
<script>
import Header from "../organisms/Header";
import SidebarID from "../organisms/SidebarID";
import SidebarAI from "../organisms/SidebarAI";

export default {
  name: "Front",
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  components: { Header, SidebarID, SidebarAI },
  props: {},
  methods: {}
};
</script>

<style>
.front {
  position: absolute;
  margin: 0px;
  width: 100%;
  height: 100%;
  /*backdrop-filter: blur(1px);
  filter: blur(1px);*/
}
.front-space {
  margin-bottom: 20px;
}
</style>
