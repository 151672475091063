<template>
  <div class="sidebar-ai" v-if="$store.state.changeSlideBarAIState.value">
    <span class="dot" :style="style"><span class="v-line" :style="style"></span></span>
    <div class="text-menu" :style="styleText" v-on:click="goToIA1">APRENDIZAJE POR REFUERZO</div>
    <span class="dot" :style="style"></span>
    <div class="text-menu" :style="styleText" v-on:click="goToIA2">REDES NEURONALES Y APRENDIZAJE PROFUNDO </div>
  </div>
</template>

<script>
export default {
  name: "SidebarAI",
  data() {
    return {
      style: {
        background: "#ffffff"        
      },
      styleText: {
        color: "#ffffff"        
      },
    }
  },
  methods: {
    goToIA1() {
      this.$router.push({ name: "ai1" });
    },
    goToIA2() {
      this.$router.push({ name: "ai2" });
    },
    goToIA3() {
      this.$router.push({ name: "ai3" });
    },
    goToIA4() {
      this.$router.push({ name: "ai4" });
    },
  },
  watch: {
    "$store.state.changeSlideBarAIColor.activate": function (activate) {
      if (activate) {
        this.style.background = this.$store.state.changeSlideBarAIColor.color;
        this.styleText.color = this.$store.state.changeSlideBarAIColor.color;
        this.$store.state.changeSlideBarAIColor.activate = false;
      }
    }
  }
};
</script>

<style>
@media screen and (min-width: 700px) {
  .sidebar-ai {
    position: fixed;
    left: 5vw;
    top: 40vh;
    animation: 2s ease-out 0s 1 slideBarAIAppear1;
  }

  @keyframes slideBarAIAppear1 {
    0% {
      transform: scaleY(0.9);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

@media screen and (max-width: 700px) {
  .sidebar-ai {
    position: fixed;
    left: -30px;
    top: 100px;
    animation: 2s ease-out 0s 1 slideBarAIAppear2;
    transform: scale(0.7);
  }

  @keyframes slideBarAIAppear2 {
    0% {
      transform: scale(0.6);
      opacity: 0;
    }
    100% {
      transform: scale(0.7);
      opacity: 1;
    }
  }
}

.dot {
  height: 7px;
  width: 7px;
  margin-right: 2px;
  background-color: #ffff;
  border-radius: 50%;
  display: inline-block;
}
.v-line {
  height: 50px;
  width: 0.5px;
  margin: 7px 0 0 3px;
  background-color: #ffff;
  display: block;
}

.text-menu {
  transition: 0.5s;
  opacity: 0.8;
  margin-top: -15px;
  margin-left: 15px;
  line-height: 20px;
  height: 50px;
}
.text-menu:hover {
  font-size: 18px;
  opacity: 1;
  color: #ffffff !important;
}
</style>