<template>
  <div>
    <transition name="fade">
      <div :class="'loading'" v-show="percentage < 100">
        <div v-if="loaded" class="loading-logo-back" :style="{'height': (2 + percentage * 0.01 * 50 * 0.98) + 'px', 'margin-top': (36 - (2 + percentage * 0.01 * 50 * 0.98)) + 'px'}">
        </div>
        <div class="loading-logo">
          <img src="../../assets/null_space-04.svg" @load="onLoad"/>
        </div>
      </div>
    </transition>
  </div>
</template>
 
<script>
import Logo from "./../atoms/Logo.vue";

export default {
  name: "Front",
  data() {
    return {
      loaded: false,
    };
  },
  components: { Logo },
  props: {},
  methods: {
    onLoad() {
      this.loaded = true;
    }
  },
  computed: {
    percentage() {
      return (
        (this.$store.state.model3d.loading.brain +
        this.$store.state.model3d.loading.robot +
          this.$store.state.model3d.loading.back360) /
        3
      );
    },
  }
};
</script>

<style>
.loading {
  position: absolute;
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

}
.loading-logo {
  position: absolute;
  margin-bottom: 15px;
  width: 300px;
  overflow: hidden;
}
.loading-logo-back {
  position: absolute;
  background-color: rgba(245, 245, 245, 0.823);
  margin-top: -27px;
  width: 300px;
}
.loading-text {
  margin-left: calc(50vw - 40vw);
  margin-top: 0.5vw;
  color: white;
  size: 30px;
}
.loading-base-bar {
  width: 80vw;
  height: 30px;
  margin-left: calc(50vw - 40vw);
  background: #082039fa;
  box-shadow:  10px solid #44b0b8;
  border-radius: 10px;
  overflow: hidden;
}
.loading-bar {
  width: 0%;
  height: 30px;
  margin-left: 0vw;
  background-color: #44b0b8;
  transition: 4s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 3s linear;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
