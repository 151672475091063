<template>
  <div class="ID">
    <InfoViewer
      :top="10"
      :left="40"
      :width="20"
      :height="0"
      :stop="5"
      :sleft="20"
      :swidth="60"
      :sheight="0"
    >
    </InfoViewer>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToServices"></Button>
    <InfoViewer :top="25" :left="55" :width="30"> </InfoViewer>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import SidebarID from "../organisms/SidebarID";

export default {
  name: "ID",
  components: { Button, InfoViewer, SidebarID },
  props: {},
  methods: {
    goToID1() {
      this.$router.push({ name: "id1" });
    },
    goToID2() {
      this.$router.push({ name: "id2" });
    },
    goToID3() {
      this.$router.push({ name: "id3" });
    },
    goToID4() {
      this.$router.push({ name: "id4" });
    },
    goToID5() {
      this.$router.push({ name: "id5" });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: -1.2,
      y: 1.1,
      zoom: 40,
      centerx: 40,
      centery: 5,
      centerz: 0,
      sx: -1,
      sy: 1,
      szoom: 30,
      scenterx: 40,
      scentery: 12,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 326, s: 0.74, l: 0.52 });
    this.$store.commit("changeBackgroud360Image", { index: 2 });
    this.$store.commit("activateBrain", { active: true });
    this.$store.commit("activateRobot", { active: false });
    this.$store.commit("updateVideoVisibility", { active: false });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    this.$store.commit("changeSlideBarIDState", { value: true });
    this.$store.commit("changeSlideBarIDColor", { color: "#FFFFFF" });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false
      }
    }
  }
};
</script>
<style>
.ID {
  color: white;
}
</style>
