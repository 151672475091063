<template>
  <div class="swap-arrows"> 
    <div class="swap-arrows-content"> 
        <div class="swap-arrows-icon" @click="upClick">
            <img class="swap-arrows-icon" :src="publicPath + 'icons/swap-arrows.png'">
        </div>
        <div class="swap-arrows-icon swap-arrows-icon-inverted"  @click="downClick">
            <img class="swap-arrows-icon" :src="publicPath + 'icons/swap-arrows.png'">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Icon",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    upClick() {
      this.$emit("upClick");
    },
    downClick() {
      this.$emit("downClick");
    }
  }
};
</script>

<style>
.swap-arrows {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  animation-duration: 3s;
  animation-name: changeArrowOpacity;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation: 2s ease-out 0s 1 AI1BlockAppear;
}

@keyframes changeArrowOpacity {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 1;
  }
}

@media screen and (min-width: 700px) {
  .swap-arrows {
    display: none;
  }
}

.swap-arrows-content {
  width: 20px;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.swap-arrows-icon {
  display: inline-block;
  width: 100%;
  opacity: 0.7;
  transition: 0.3s;
}

.swap-arrows-icon-inverted {
    transform: rotateZ(180deg);
}

</style>
