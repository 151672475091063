<template>
  <div class="menu">
    <div class="menu-icon-container" v-on:click="changeMenuState">
      <div class="menu-icon">
        <Icon :src="'close.png'"></Icon>
      </div>
    </div>
    <div class="menu-items">
      <div class="menu-item" v-on:click="goToIA">
        <div class="menu-item-text">
          INTELIGENCIA ARTIFICIAL
        </div>
      </div>
      <div class="menu-item" v-on:click="goToID">
        <div class="menu-item-text">
          DISEÑO INTERACTIVO
        </div>
      </div>
      <div class="menu-item" v-on:click="goToContact">
        <div class="menu-item-text">
          CONTACTO
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../atoms/Icon";

export default {
  name: "Menu",
  data() {
    return {
      innerWidth: window.innerWidth
    }
  },
  components: { Icon },
  methods: {
    goToContact() {
      this.$router.push({ name: "contact" });
      this.$store.commit("changeMenuState", { value: false });
    },
    goToIA() {
      this.$router.push({ name: 'ai' });
      this.$store.commit("changeMenuState", { value: false });
    },
    goToID() {
      this.$router.push({ name: 'id' });
      this.$store.commit("changeMenuState", { value: false });
    },
    changeMenuState() {
      this.$store.commit("changeMenuState", { value: !this.$store.state.changeMenuState.value });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
.menu {
  position: absolute;
  top: 0vh;
  left: 50vw;
  width: 50vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999;
}

.menu-icon-container {
  display: flex;
  width: 100%;
}

.menu-icon {
  margin-left: auto; 
  display: inline-block;
  width: 15px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 10px; 
}
.menu-items {
  width: calc(100% - 40px);
  height: 100%;
  padding: 20px;
}
.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgb(150, 150, 150);
}
.menu-item-text {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  color: rgb(150, 150, 150);
}
.menu-item-text:hover {
  color: rgb(0, 0, 0);
}
</style>