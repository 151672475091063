<template>
  <div class="contact">
    <InfoViewer :top="10" :left="40" :width="20" :height="0" :stop="0" :sleft="20" :swidth="60" :sheight="0"> 
      <div class="contact-title">
        Contacto
      </div>
    </InfoViewer> 
    <InfoViewer :top="20" :left="10" :width="40" :height="0" :stop="10" :sleft="10" :swidth="80" :sheight="0"> 
      <div class="contact-form">
        <ContactForm></ContactForm>
      </div>
    </InfoViewer> 
    <Button
      :text="'ATRÁS'"
      :styleType="4"
      v-on:click="goToServices"
    ></Button>
  </div>
</template>
 
<script>
// Correo nullspacesas@gmail.com

import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import ContactForm from "../organisms/ContactForm";

export default {
  name: "Contact",
  components: { Button, InfoViewer, ContactForm },
  props: {},
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },
  },
  mounted() {
    this.$store.commit("goToPosition", { x: Math.PI / 2, y: Math.PI / 2, zoom: 5, centerx: 20, centery: 5, centerz: 0, sx: Math.PI / 2, sy: Math.PI / 2, szoom: 5, scenterx: 20, scentery: 5, scenterz: 0 });
    this.$store.commit("changeBaseColor", { h: 174, s: 1, l: 0.44 });
    this.$store.commit("changeBackgroud360Image", { index: 0});
    this.$store.commit("activateBrain", { active: true });
    this.$store.commit("activateRobot", { active: false });
    this.$store.commit("updateVideoVisibility", { active: false });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    this.$store.commit("changeSlideBarIDState", { value: false });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false
      }
    }
  }
};
</script>
<style>
.contact {
    color: white;
}
.contact-title {
  width: 100%;
  text-align: center;
  font-family: 'Roboto';
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
}
</style>
