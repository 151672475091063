<template>
  <button
    v-on:click="onClick"
    :class="
      styleType == 1
        ? 'button-type-1'
        : styleType == 2
        ? 'button-type-2'
        : styleType == 3
        ? 'button-type-3'
        : styleType == 4
        ? 'button-type-4'
        : styleType == 5
        ? 'button-type-5'
        : 'button-type-6'
    "
  >
    <div class="button-content">
      <div v-if="styleType == 4" class="button-icon-content">
        <div class="button-icon">
          <Icon :src="'arrow.png'"></Icon>
        </div>
      </div>
      <div
        v-if="styleType == 6 && innerWidth < 700"
        class="button-icon-content"
      >
        <div class="button-icon">
          <Icon :src="'hamburger.png'"></Icon>
        </div>
      </div>
      <span class="button-type-text">{{ text.toUpperCase() }}</span>
    </div>
  </button>
</template>

<script>
import Icon from "./Icon";

export default {
  name: "Button",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: {
    Icon,
  },
  props: {
    text: String,
    styleType: Number,
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.button-type-text {
  display: inline-block;
  transition: 0.3s;
  width: 100%;
}

.button-type-1 {
  /* Borde adobe */
  background: var(--unnamed-color-1468e6) 0% 0% no-repeat padding-box;
  background: rgba(100, 100, 100, 0.7);
  border-radius: 1000px;
  opacity: 1;
  border: 2.4px solid rgba(255, 255, 255, 0.9);

  /* Fuente adobe */
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  opacity: 1;

  /* Configuraciones */
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 43px;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  animation: 2s ease-out 0s 1 ButtonAppear;
}

.button-type-1:hover {
  background: rgba(83, 238, 255, 0.3);
  border: 2.4px solid rgb(2, 230, 215);
}

.button-type-1:hover .button-type-text {
  transform: scale(1.05);
}

.button-type-2 {
  /* Borde adobe */
  background: var(--unnamed-color-1468e6) 0% 0% no-repeat padding-box;
  background: rgba(100, 100, 100, 0.7);
  border-radius: 1000px;
  opacity: 1;
  border: 2.4px solid rgba(255, 255, 255, 0.9);

  /* Fuente adobe */
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  opacity: 1;

  /* Configuraciones */
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 43px;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.3s;
  animation: 2s ease-out 0s 1 ButtonAppear;
}

.button-type-2:hover {
  background: rgba(255, 83, 218, 0.3);
  border: 2.4px solid rgb(214, 34, 175);
}

.button-type-2:hover .button-type-text {
  transform: scale(1.05);
}

.button-type-3 {
  /* Borde adobe */
  background: #082039fc 0% 0% no-repeat padding-box;
  border: 2px solid #5bf4ff;
  border-radius: 21px;

  /* Fuente adobe */
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 18px/22px
    var(--unnamed-font-family-museo-sans-500);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 18px/22px Museo Sans 500;
  letter-spacing: 2.34px;
  color: #ffffff;
  opacity: 1;

  /* Configuraciones */
  outline: none;
  box-shadow: none;
  height: 43px;
  padding-left: 15px;
  padding-right: 15px;
}

.button-type-3:hover {
  background: #0e3662fc;
  border: 2.4px solid rgb(2, 180, 230);
}

.button-type-3:hover .button-type-text {
  transform: scale(1.05);
}

.button-type-4 {
  /* Fuente adobe */
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  color: #ffffffaf;
  opacity: 1;
  width: 0px;

  /* Configuraciones */
  position: absolute;
  outline: none;
  box-shadow: none;
  padding-left: 20px;
  padding-right: 20px;
  top: 90px;
  transition: 0.3s;
  animation: 2s ease-out 0s 1 ButtonAppear;
  background-color: transparent;
  border: none;
  z-index: 9999;
}

.button-type-4:hover {
  color: #ffffff;
}

.button-type-4 .button-type-text {
  margin-top: -3px;
}

.button-type-4:hover .button-type-text {
  transform: scale(1.05);
}

@media screen and (max-width: 700px) {
  .button-type-4 {
    color: transparent;
  }
  .button-type-4:hover {
    color: transparent;
  }
}

.button-type-5 {
  /* Borde adobe */
  border: 2px solid var(--unnamed-color-ffffff);
  border: 2px solid #ffffff;
  border-radius: 1000px;
  opacity: 0.83;

  /* Fuente adobe */
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 18px/22px
    var(--unnamed-font-family-museo-sans-500);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 18px/22px Museo Sans 500;
  letter-spacing: 2.34px;
  color: #ffffff;
  opacity: 1;

  /* Configuraciones */
  outline: none;
  box-shadow: none;
  height: 43px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;

  transition: 0.3s;
}

.button-type-5:hover {
  border: 2px solid transparent;
  color: #ffffff;
}

.button-type-5:hover .button-type-text {
  transform: scale(1.07);
}

.button-type-6 {
  /* Borde adobe */
  background: var(--unnamed-color-1468e6) 0% 0% no-repeat padding-box;
  background: rgba(100, 100, 100, 0.7);
  border-radius: 1000px;
  opacity: 1;
  border: 2.4px solid rgba(255, 255, 255, 0.9);

  /* Fuente adobe */
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  opacity: 1;

  /* Configuraciones */
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 43px;
  padding-left: 20px;
  padding-right: 20px;
  transition: none;
  transform: scale(0.7);
}

.button-type-6:hover {
  background: rgba(255, 255, 255, 0.843);
  border: 2.4px solid rgba(255, 255, 255, 0.789);
  font-weight: 600;
  color: black;
}

.button-type-6 .button-type-text {
  transition: none;
}

.button-type-6:hover .button-type-text {
  transform: scale(1.05);
}

@media screen and (max-width: 700px) {
  .button-type-6 {
    color: transparent;
    width: 50px;
    background: none;
    border: none;
    transform: scale(1.3);
  }
  .button-type-6:hover {
    color: transparent;
    background: none;
    border: none;
  }
}

@keyframes ButtonAppear {
  0% {
    transform: scaleY(0.9);
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}

.button-icon {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
}

.button-icon-content {
  display: inline-block;
}
.button-content {
  display: flex;
  align-items: center;
}
</style>
