<template>
  <div class="ID3" id="ID3">
    <SwapArrows v-on:upClick="goToID2" v-on:downClick="goToID4"></SwapArrows>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToID"></Button>
    <div class="AI1-block">
      <div class="AI2-block-content">
        <div class="AI2-subtitle1">APLICACIONES</div>
        <div class="AI2-subtitle2">WEB Y MÓVIL</div>
        <div class="AI1-space"></div>
        <div class="AI2-description">
          Desarrollo de plataformas Web y Móviles con una interacción de usuario
          dinámica y backend confiable y robusto.
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import Image1 from "../atoms/Image1";
import SidebarID from "../organisms/SidebarID";
import Swipe from "../../vendor/Swipe";
import SwapArrows from "../molecules/SwapArrows";

export default {
  name: "ID3",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { Button, InfoViewer, Image1, SidebarID, SwapArrows },
  props: {},
  methods: {
    goToID() {
      this.$router.push({ name: "id" });
      this.$store.commit("goToShape", { figureName: "cube" });
    },
    goToID2() {
      this.$router.push({ name: "id2" });
    },
    goToID4() {
      this.$router.push({ name: "id4" });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 700) {
        this.$store.commit("changeSlideBarIDState", { value: false });
      } else {
        this.$store.commit("changeSlideBarIDState", { value: true });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: -0.35,
      y: 1.8,
      zoom: 40,
      centerx: 20,
      centery: 12,
      centerz: 0,
      sx: -0.35,
      sy: 1.8,
      szoom: 40,
      scenterx: 20,
      scentery: 12,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 201, s: 0.92, l: 0.53 });
    this.$store.commit("changeBackgroud360Image", { index: 2 });
    this.$store.commit("activateBrain", { active: false });
    this.$store.commit("activateRobot", { active: true });
    this.$store.commit("changeBackgroudVideo", { src: "appsweb.mp4" });
    this.$store.commit("updateVideoVisibility", { active: true });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    if (this.innerWidth < 700) {
      this.$store.commit("changeSlideBarIDState", { value: false });
    } else {
      this.$store.commit("changeSlideBarIDState", { value: true });
    }
    this.$store.commit("changeSlideBarIDColor", { color: "#18A9F5" });
    Swipe.detectSwipe("ID3", (el, dir) => {
      if (dir == "up") {
        this.$router.push({ name: "id2" });
      } else if (dir == "down") {
        this.$router.push({ name: "id4" });
      }
    });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false;
      }
    },
  },
};
</script>
<style>
.ID3 {
  color: white;
  width: 100%;
  height: 100%;
}
</style>
