<template>
  <div id="app">
    <!-- <Vue3DModel></Vue3DModel> -->
    <Base></Base>
    <!--     <Three></Three> -->
  </div>
</template>

<script>
//import Vue3DModel from "./components/test_models/Vue3DModel";
import Base from "./components/templates/Base";
import Three from "./components/test_models/Three";
export default {
  name: "App",
  components: {
    Base,
    //Vue3DModel,
    Three,
  },
};
</script>

<style>
.character-style-1 {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--color8);
  line-height: 14px;
  letter-spacing: 1px;
}

.character-style-2 {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: var(--color5);
  line-height: 24px;
  letter-spacing: 1px;
}

.character-style-3 {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--color5);
  line-height: 14px;
  letter-spacing: 1px;
}

.character-style-4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
  color: #ffffff;
}

.character-style-5 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #ffffff;
  line-height: 10px;
}
/*
.character-style-6{
  font-family: ;
  font-style: ;
  font-weight: ;
  font-size: ;
  color: ;
  line-height: ;
  letter-spacing: ;
}

.character-style-7{
  font-family: ;
  font-style: ;
  font-weight: ;
  font-size: ;
  color: ;
  line-height: ;
  letter-spacing: ;
}
 */

body {
  background-color: #000000;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0000;
}

:root {
  --color1: #041321;
  --color2: #0be6f6;
  --color3: #67b5e2;
  --color4: #1468e6;
  --color5: #eeeeee;
  --color6: #ffffff;
  --color7: #2f2f2f;
  --color8: #707070;
  --color9: #525252;
  --color10: #97979c;
  --color11: #f3f3f414;
  --color12: #de1d1d;

  /* Colors: */
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-1468e6: #1468e6;
  --unnamed-color-97979c: #97979c;
  --unnamed-color-707070: #707070;
  --unnamed-color-000000: #000000;

  /* Font/text values */
  --unnamed-font-family-noto-sans: Noto Sans;
  --unnamed-font-family-museo-sans-500: Museo Sans 500;
  --unnamed-font-family-museo-sans-900: Museo Sans 900;
  --unnamed-font-family-museo-sans-300: Museo Sans 300;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600px;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-19: 19px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-25: 25px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-35: 35px;
  --unnamed-font-size-40: 40px;
  --unnamed-font-size-60: 60px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-26: 26px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-line-spacing-42: 42px;
  --unnamed-line-spacing-45: 45px;
  --unnamed-line-spacing-48: 48px;
  --unnamed-line-spacing-72: 72px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-noto-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-19);
  line-height: var(--unnamed-line-spacing-26);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-eeeeee);
}
.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-noto-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-97979c);
}
.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-museo-sans-500);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-4 {
  font-family: var(--unnamed-font-family-museo-sans-900);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-42);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-5 {
  font-family: var(--unnamed-font-family-museo-sans-900);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-40);
  line-height: var(--unnamed-line-spacing-48);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-museo-sans-900);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-60);
  line-height: var(--unnamed-line-spacing-72);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-7 {
  font-family: var(--unnamed-font-family-museo-sans-300);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
.unnamed-character-style-8 {
  font-family: var(--unnamed-font-family-museo-sans-300);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-45);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
}
</style>
