<template>
  <div>
    <div class="radio-button-text">
      <RadioButton :group="group"></RadioButton>
    </div>
    &nbsp;
    <div class="radio-button-text">
      <InputLabel :text="text" :obligatory="obligatory"></InputLabel>
    </div>
  </div>
</template>

<script>
import RadioButton from "../atoms/RadioButton";
import InputLabel from "../molecules/InputLabel";
export default {
  name: "RadioButtonText",
  props: {
    text: String,
    obligatory: Boolean,
    group: String,
  },
  components: {
    RadioButton,
    InputLabel,
  },
  methods: {},
};
</script>

<style scoped>
.radio-button-text {
  display: inline-block;
}
</style>
