<template>
  <div class="back"></div>
</template>

<script>
export default {
  name: "Back",
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  components: {},
  props: {},
  methods: {},
};
</script>

<style>
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 500px;
  color: rgb(42, 42, 42);
  background-color: black;
}
</style>
