<template>
  <div class="contact-form">
    <InputText :placeholder="'Nombre'" :value="nameFun"></InputText>
    <div class="contact-form-space"></div>
    <InputText :placeholder="'E-mail'" :value="emailFun"></InputText>
    <div class="contact-form-space"></div>
    <InputTextArea :placeholder="'Mensaje'" :value="messageFun"></InputTextArea>
    <div class="contact-form-space"></div>
    <div class="contact-form-button">
      <Button :text="'Enviar'" :styleType="3" v-on:click="sendEmail"></Button>
    </div>
  </div>
</template>

<script>
import Subtitulo2 from "../atoms/Subtitulo2";
import Description2 from "../atoms/Description2";
import InputText from "../atoms/InputText";
import InputTextArea from "../atoms/InputTextArea";
import InputLabel from "../molecules/InputLabel";
import RadioButtonText from "../molecules/RadioButtonText";
import Button from "../atoms/Button";
import emailjs from "emailjs-com";

export default {
  name: "ContactForm",
  components: {
    Subtitulo2,
    Description2,
    InputLabel,
    InputText,
    InputTextArea,
    RadioButtonText,
    Button,
  },
  data() {
    return {
      templateParams: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    nameFun(value) {
      this.templateParams.name = value;
    },
    emailFun(value) {
      this.templateParams.email = value;
    },
    messageFun(value) {
      this.templateParams.message = value;
    },
    sendEmail() {
      if (!this.templateParams.name) {
        alert("Por favor ingrese su nombre");
      } else if (!this.templateParams.email) {
        alert("Por favor ingrese un correo de contacto");
      } else if (!this.templateParams.message) {
        alert("Por favor ingrese un mensaje");
      } else {
        emailjs
          .send(
            "service_eh3tuxc",
            "contact_form",
            this.templateParams,
            "user_bYwhHp67bTyelRz9NljGM"
          )
          .then(
            (response) => {
              console.log("SUCCESS", response.status, response.text);
              alert("¡Hemos recibido su mensaje!");
            },
            (err) => {
              console.log("FAILED", err);
              alert("Ha habido un error al enviar el mensaje.");
            }
          );
      }
    },
  },
};
</script>

<style>
.contact-form {
  width: 100%;
  height: 0%;
}
.contact-form-space {
  width: 10px;
  height: 10px;
}
.contact-form-button {
  margin-left: calc(100% - 100px);
}
</style>
