<template>
  <div class="AI2" id="AI2">
    <SwapArrows v-on:upClick="goToIA1" v-on:downClick="goToIA1"></SwapArrows>
    <div class="AI1-block">
      <div class="AI1-block-content">
        <div class="AI1-subtitle1 AI1-right">REDES <b>NEURONALES</b></div>
        <div class="AI1-subtitle1 AI1-right">Y APRENDIZAJE <b>PROFUNDO</b></div>
        <div class="AI1-space"></div>
        <div class="AI1-description">
          Desarrollamos e investigamos aplicaciones y soluciones basadas en :
          Redes Neuronales Convolucionales (CNN), Redes Neuronales Recurentes
          (RNN), Redes Adversarias Generativas (GAN), Perceptrón Multicapa
          (MLP).
        </div>
      </div>
    </div>
    <Button :text="'ATRÁS'" :styleType="4" v-on:click="goToIA"></Button>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";
import Image1 from "../atoms/Image1";
import SidebarAI from "../organisms/SidebarAI";
import Swipe from "../../vendor/Swipe";
import SwapArrows from "../molecules/SwapArrows";

export default {
  name: "AI2",
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  components: { Button, InfoViewer, Image1, SidebarAI, SwapArrows },
  props: {},
  methods: {
    goToIA() {
      this.$router.push({ name: "ai" });
      this.$store.commit("goToShape", { figureName: "cube" });
    },
    goToIA1() {
      this.$router.push({ name: "ai1" });
    },
    onResize() {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 700) {
        this.$store.commit("changeSlideBarAIState", { value: false });
      } else {
        this.$store.commit("changeSlideBarAIState", { value: true });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$store.commit("goToPosition", {
      x: 0.35,
      y: 1.8,
      zoom: 40,
      centerx: 20,
      centery: 12,
      centerz: 0,
      sx: 0.35,
      sy: 1.8,
      szoom: 40,
      scenterx: 20,
      scentery: 12,
      scenterz: 0,
    });
    this.$store.commit("changeBaseColor", { h: 223, s: 1, l: 0.3 });
    this.$store.commit("changeBackgroud360Image", { index: 1 });
    this.$store.commit("activateBrain", { active: false });
    this.$store.commit("activateRobot", { active: true });
    this.$store.commit("changeBackgroudVideo", { src: "redesneuronales.mp4" });
    this.$store.commit("updateVideoVisibility", { active: true });
    this.$store.commit("changeMenuState", { value: false });
    if (this.innerWidth < 700) {
      this.$store.commit("changeSlideBarAIState", { value: false });
    } else {
      this.$store.commit("changeSlideBarAIState", { value: true });
    }
    this.$store.commit("changeSlideBarIDState", { value: false });
    this.$store.commit("changeSlideBarAIColor", { color: "#0080F4" });
    Swipe.detectSwipe("AI2", (el, dir) => {
      if (dir == "up") {
        this.$router.push({ name: "ai1" });
      } else if (dir == "down") {
        this.$router.push({ name: "ai1" });
      }
    });
  },
  watch: {
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false;
      }
    },
  },
};
</script>
<style>
.AI2 {
  color: white;
  width: 100%;
  height: 100%;
}
</style>
