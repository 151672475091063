<template>
  <div class="home">
    <InfoViewer
      :top="10"
      :left="20"
      :width="60"
      :height="0"
      :stop="5"
      :sleft="10"
      :swidth="80"
      :sheight="0"
    >
      <div class="home-text">
        Equipo de desarrollo enfocado en investigación, innovación e
        implementación de <b>Inteligencia Artificial</b> para realidades inmersivas.
      </div>
    </InfoViewer>
  </div>
</template>
 
<script>
import Button from "../atoms/Button";
import InfoViewer from "../organisms/InfoViewer";

export default {
  name: "Home",
  data() {
    return {
      active: true,
    };
  },
  components: { Button, InfoViewer },
  props: {},
  methods: {
    goToContact() {
      this.$router.push({ name: "contact" });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },
  },
  mounted() {
    this.$store.commit("changeBaseColor", { h: 229, s: 0.7, l: 0.5 });
    this.$store.commit("changeBackgroud360Image", { index: 0 });
    this.$store.commit("activateBrain", { active: true });
    this.$store.commit("activateRobot", { active: false });
    this.$store.commit("updateVideoVisibility", { active: false });
    this.$store.commit("changeMenuState", { value: false });
    this.$store.commit("changeSlideBarAIState", { value: false });
    this.$store.commit("changeSlideBarIDState", { value: false });
    this.$store.commit("goToPosition", {
      x: Math.PI / 2,
      y: Math.PI / 2,
      zoom: 60,
      centerx: 20,
      centery: 12,
      centerz: 0,
      sx: Math.PI / 2,
      sy: Math.PI / 2,
      szoom: 50,
      scenterx: 20,
      scentery: 10,
      scenterz: 0,
    });

    if (this.percentage == 100) {
      const self = this;
      setTimeout(function () {
        if (self.active) {
          self.goToServices();
        }
      }, 10000);
    }
  },
  beforeDestroy() {
    this.active = false;
  },
  watch: {
    percentage(newValue) {
      if (newValue == 100) {
        const self = this;
        setTimeout(function () {
          if (self.active) {
            self.goToServices();
          }
        }, 10000);
      }
    },
    "$store.state.updateSameView.activate": function (activate) {
      if (activate) {
        this.$store.commit("goToPosition");
        this.$store.state.updateSameView.activate = false;
      }
    },
  },
  computed: {
    percentage() {
      return (
        (this.$store.state.model3d.loading.brain +
          this.$store.state.model3d.loading.robot +
          this.$store.state.model3d.loading.back360) /
        3
      );
    },
  },
};
</script>

<style>
.home {
  color: white;
}
.home-text {
  text-align: center;
  font-family: "Roboto";
  font-size: 23px;
  font-weight: 300;
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .home-text {
    font-size: 15px;
  }
}
</style>
